import React from 'react';
import '../App.css';
import DownArrow from '../icons/down.png';
import rightArrow from "../icons/right.png";
import {Link} from 'react-router-dom';
import {isMobile} from 'react-device-detect';


function ArrowNext(props) {

  const nextPage = Number(props.currentPage) + 1;
  if ((props.currentPage < 27 && props.currentPage >= 1) || (isMobile && (props.currentPage < 28 && props.currentPage >= 1))) {
    return (
      <div className="downArrow">
            <Link to={'/' + nextPage} onClick={props.handleClick()}> <img src={isMobile ? rightArrow : DownArrow} alt="downArrow"/> </Link>
      </div>
    )} else {
    return null
  }
}

export default ArrowNext;
