import React from 'react';
import '../Landing.css';
import { withGlobalState } from 'react-globally';
// import ReconButton  from '../partials/ReconVideoPlatform';

class EventLanding extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentLanguage: this.props.globalState.currentLanguage,
      data: this.props.data,
      goToPage: this.props.goToPage,
      is_archive: false,
    };
    this.eventScroller = React.createRef();
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount(){
  }

  componentDidUpdate(){
    if (typeof this.eventScroller.current !== 'undefined' && this.eventScroller.current !== null) {
      this.eventScroller.current.scrollTop = 0;
    }
  }

  componentWillMount(){
    this.props.setGlobalState({data: this.props.globalState.data})
  }

  handleClick(page){
    console.log('triggered')
    this.props.goToPage(page)
  }

  replace_orgs_with_links(text){
      let regex_GIEI = /GIEI/g;
      let regex_SITU = /SITU/g;
      let regex_EAAF = /EAAF/g;
      let linked_text = text.replace(regex_GIEI, "<a href='http://www.gieinicaragua.org'>GIEI</a>");
      linked_text = linked_text.replace(regex_SITU, "<a href='https://situ.nyc/'>SITU</a>");
      linked_text = linked_text.replace(regex_EAAF, "<a href='https://www.eaaf.org/'>EAAF</a>");
      return linked_text
  }

dataRender(eventData, language){
    return ( <>
            <h2 className="eventLandingSubtitle">{eventData[language].Landing_Subtext}</h2>
            <h1 className="eventLandingTitle">{eventData[language].Landing_Title}</h1>
            <div className="eventParent" ref={this.eventScroller}>
              <p className="eventLandingText" dangerouslySetInnerHTML={{__html:this.replace_orgs_with_links(eventData[language].Landing_Info)}}></p>
            </div>
            </>
          );
}

  eventLandingRender() {
      const globalData = this.props.globalState.data.data;
      const currentLanguage = this.props.globalState.currentLanguage;
      let eventData = '';
      switch (Number(this.props.globalState.currentPage)) {
        case 2:
          eventData = globalData[1].page_2_18abril_landing;
          return this.dataRender(eventData, currentLanguage);
        case 10:
          eventData = globalData[9].page_10_UNI_Managua_landing;
          return this.dataRender(eventData, currentLanguage);
        case 15:
          eventData = globalData[14].page_15_parque_esteli_landing;
          return this.dataRender(eventData, currentLanguage);
        case 21:
          eventData = globalData[20].page_21_marcha_landing;
          return this.dataRender(eventData, currentLanguage);
        default:
          return null;
      }
}

finalRender(){
  const dataRender = this.eventLandingRender();
  if (dataRender === null){
    return null
  } else {
    return (
      <div className="component">
          { dataRender }
      </div>
    )
  }
}
  render() {
    return (
      <>
        {this.finalRender()}
      </>
    )
  }
}
export default withGlobalState(EventLanding);

//for adding recon button to event 4 landing
// dataRender(eventData, language){
//     return ( <>
//             <h2 className="eventLandingSubtitle">{eventData[language].Landing_Subtext}</h2>
//             <h1 className="eventLandingTitle">{eventData[language].Landing_Title}</h1>
//             <div className="eventParent" ref={this.eventScroller}>
//               <p className="eventLandingText" dangerouslySetInnerHTML={{__html:this.replace_orgs_with_links(eventData[language].Landing_Info)}}></p>
//             </div>
//             <ReconButton />
//             </>
//           );
