import React from 'react';
import { withGlobalState } from 'react-globally';
import {isMobile} from 'react-device-detect';
import '../App.css';

class CarouselMobileBars extends React.Component {
  constructor (props) {
   super(props);
   this.state = {
     mediaData: this.props.globalState.mediaData,
     currentImageIndex: 0,
     currentLanguage: this.props.currentLanguage,
     currentPage: this.props.currentPage,
     onClick: this.props.onClick,
     active_index: 0
   };
 }

 renderCarouselBars(){
   let table = [];
   const globalData = this.props.globalState.data.data;
   const allChapterData = globalData[this.props.globalState.currentPage - 1];
   const renderData = allChapterData[Object.keys(allChapterData)[0]];
   const eventKey = renderData.event_key;
   const subeventKey = eventKey.subevent;
   const chapter = eventKey.chapter;
   const chapterArr = this.state.mediaData[eventKey.event][subeventKey][chapter];
   let inNarrativeArr = [];
   let assetCodes = [];
   for (let x = 0; x < chapterArr.length; x++) {
     if (chapterArr[x].in_narrative === true) {
       const currentMedia = chapterArr[x];
       inNarrativeArr.push(currentMedia);
       assetCodes.push(currentMedia.asset_code);
     }
   }
   for (let x = 0; x < inNarrativeArr.length; x++) {
     table.push(<div
       className={(this.props.globalState.active_index === x) ? "carouselAssetBarActive" : "carouselAssetBar"}
       onClick={(e) => {this.props.onClick(e, inNarrativeArr[x].asset_code, x)}}
       key={x}></div>)
   }
   return table
}

 render () {
   if (isMobile) {
       return (
         <div className="barDiv">
           {this.renderCarouselBars()}
         </div>
       )
     } else {
       return null;
     }
   }
}

export default withGlobalState(CarouselMobileBars);
