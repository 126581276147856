import React from 'react';
import Timeline from "react-timelinejs";
import moment from 'moment';
import { withGlobalState } from 'react-globally';
import {isMobile} from 'react-device-detect';

const starting_date = moment('2018-04-17');

const ending_date = moment('2018-06-01');

const time_between = moment.duration(ending_date.diff(starting_date));

const lines = () => {
    /* Adds a single line by day for demarcation */
    const days_between = time_between.asDays();
    const date = starting_date.clone();
    const plot_lines = [];
    for (let i = 0; i < days_between; i++) {
        plot_lines.push({
            date: date.add(1, 'days').toDate()
        });
    }
    return plot_lines;
};

const eventNames = {
    1: "NICARAGUA 18 DE ABRIL 2018",
    2: "UNI Managua",
    3: "Parque Esteli",
    4: "Marcha de las madres "
}

const startStop = {
    1: [moment('2018-04-18 11:00:00').toDate(), moment('2018-04-18 21:33:00').toDate()],
    2: [moment('2018-04-20 10:07:00').toDate(), moment('2018-04-20 17:30:00').toDate()],
    3: [moment('2018-04-20 16:20:00').toDate(), moment('2018-04-20 22:00:00').toDate()],
    4: [moment('2018-05-30 13:51:00').toDate(), moment('2018-05-30 18:11:00').toDate()]
}

class timelineContents extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            data: [],
            lines: lines(),
            domain: [],
            event: 1,
            brushDomain: []
        }
    }

    componentWillMount(){
        this.processEvent(this.state.event, null);
    }

    componentDidMount(){
        window.addEventListener('resize', this.resized.bind(this))
        this.processEvent(1, null);
    }

    // componentWillUnmount(){
    //
    // }

    resized(){
        this.processEvent(this.state.event, null);
    }

    processEvent(id, e){
        const processed = this.loadSpecificData(id);
        const calcDomain = [processed.domainStart.toDate(), processed.domainEnd.toDate()];
        this.setState({ domain: calcDomain });
        this.setState({ brushDomain: calcDomain })
        this.setState({ data: processed.culledData });
        this.setState({ event: id });
        this.props.setGlobalState({ timeline_request: id });
        // Remove active class from all buttons
        const allButtons = document.getElementsByClassName('archiveEventButton');
        for (var x = 0; x < allButtons.length; x++){
            allButtons[x].classList.remove('archiveActive');
        }
        const thisButton = document.querySelectorAll("[data-event='" + id + "']");
        if (thisButton[0] !== undefined){
            thisButton[0].classList.add('archiveActive');
        }

    }

    calculateHeight(){
        const timelineContents = document.getElementById('timelineContents');
        const buttons = document.getElementById('timelineButtons');
        let height = document.documentElement.clientHeight * 0.5;
        if (timelineContents !== null){
            height = timelineContents.clientHeight;
        }
        if (buttons !== null) {
            height = height - buttons.clientHeight;
        }
        return height;
    }

    calculateWidth(){
        const timelineContents = document.getElementById('timelineContents');
        let width = document.documentElement.clientWidth * 0.5;
        if (timelineContents !== null){
            width = timelineContents.clientWidth;
        }
        return width;
    }

    loadSpecificData(id){
        // Okay, we're going to load the specific data
        const culledData = [];
        let domainStart = null;
        let domainEnd = null;
        // console.log('archiveData', this.props.archiveData);
        for (var x = 0; x < this.props.archiveData.length; x++){
            const thisItem = this.props.archiveData[x];
            if (thisItem.event_name === eventNames[id]){
                const thisStart = moment(thisItem.start);
                const thisEnd = moment(thisItem.end);
                if (thisStart.isSameOrAfter(starting_date) && thisEnd.isSameOrBefore(ending_date)){
                    culledData.push(thisItem);
                    if (domainStart === null) {
                        domainStart = thisStart;
                    } else {
                        if (thisStart.isBefore(domainStart)) {
                            domainStart = thisStart;
                        }
                    }
                    if (domainEnd === null) {
                        domainEnd = thisEnd;
                    } else {
                        if (thisEnd.isAfter(domainEnd)) {
                            domainEnd = thisEnd;
                        }
                    }
                }
            }
        }
        return {culledData: culledData,
            domainStart: domainStart,
            domainEnd: domainEnd
        };
    }

    handleBrush = domain => {
        this.setState({ domain })
    };

    // conditional rendering for the timeline based on desktop or mobile access
    timeLineOrNah() {
      if (isMobile) {
        return null
      } else {
        return <>
        <Timeline data={this.state.data}
            lines={[]}
            height={this.calculateHeight() / 2} width={this.calculateWidth()}
            trackHeight={20}
            range={this.state.domain}
            onClick={(item) => {
                this.props.setGlobalState({ asset_in_view: item.asset_code})
                this.props.setGlobalState({ asset_in_view_full: item });
            }}
            tooltipContent={(item)=>{
                if (this.props.globalState.currentLanguage === 'spanish'){
                    return "latitud: "+ item.lat +
                    "<br>longitud: " + item.lon +
                    "<br>hora: " + item.start_time +
                    "<br>duración: " + item.duration;
                } else {
                    return "latitude: "+ item.lat +
                    "<br>longitude: " + item.lon +
                    "<br>time: " + item.start_time +
                    "<br>duration: " + item.duration;
                }
            }}
        />
        <Timeline data={this.state.data}
            lines={[]}
            height={this.calculateHeight() / 2 - 36} width={this.calculateWidth()}
            trackHeight={5}
            label={false} tooltips={false}
            brush={true} onBrush={this.handleBrush} brushRange={startStop[this.state.event]}
        />
      </>
      }
    }


    render() {
        return (
            <div id="timelineContents">
                <div id="timelineButtons">
                    <button className={'archiveEventButton archiveActive'} data-event="1" onClick={(e) => this.processEvent(1, e)}>
                        {this.props.globalState.currentLanguage === 'spanish' ? 'Evento 1' : 'Event 1'}
                    </button>
                    <button className="archiveEventButton" data-event="2" onClick={(e) => this.processEvent(2, e)}>
                        {this.props.globalState.currentLanguage === 'spanish' ? 'Evento 2' : 'Event 2'}
                    </button>
                    <button className="archiveEventButton" data-event="3" onClick={(e) => this.processEvent(3, e)}>
                        {this.props.globalState.currentLanguage === 'spanish' ? 'Evento 3' : 'Event 3'}
                    </button>
                    <button className="archiveEventButton" data-event="4" onClick={(e) => this.processEvent(4, e)}>
                        {this.props.globalState.currentLanguage === 'spanish' ? 'Evento 4' : 'Event 4'}
                    </button>
                </div>
                {this.timeLineOrNah()}
            </div>
        );
    }
}
export default withGlobalState(timelineContents);
