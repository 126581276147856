import React from 'react';
import '../App.css';
import { withGlobalState } from 'react-globally';
import {isMobile} from 'react-device-detect';
import About from './About';
import AboutButton from '../partials/AboutButton';
import Archive from './Archive';
import ArchiveButton from '../partials/ArchiveButton';
import ArrowNext from '../partials/ArrowNext';
import ArrowPrev from '../partials/ArrowPrev';
import Carousel from './Carousel';
import CuidadoModal from '../partials/CuidadoModal';
import Event from './Event';
import EventLanding from './EventLanding';
import HomeButton from '../partials/HomeButton';
import Tweet_icon from '../icons/twitter_hex.png';
import Landing from './Landing';
import Map from './Map';
import ScrollNav from '../partials/ScrollNav';

const viewport = {
  latitude: 12.8654,
  longitude: -83.207,
  zoom: 7,
  bearing: 0,
  pitch: 0,
  //width: window.innerWidth,
  //height: window.innerHeight,
}

class Report extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: null,
            currentLanguage: props.globalState.currentLanguage,
            mediaData: props.globalState.mediaData,
            mapLoaded: props.globalState.mapLoaded
        };
        this.test = React.createRef();
        this._pageScroller = null;
        this.updateLanguage = this.updateLanguage.bind(this);
        this.languageButtons = this.languageButtons.bind(this);
        this.resetEventViewforMobileNext = this.resetEventViewforMobileNext.bind(this);
        this.resetEventViewforMobilePrev = this.resetEventViewforMobilePrev.bind(this);
        this.mapOrNah = this.mapOrNah.bind(this);
    }

    componentDidCatch(error, info) {
      console.log(error, info);
    }

    componentDidMount() {
        this.setStates(this.props.match.params.key);
        window.addEventListener('scroll', this.windowScroll);
    }

    componentWillMount() {
      window.onresize = function(){
        document.body.height = window.innerHeight;
      }
      window.onresize();
    }

    // since your id is passed as a prop (by the routing), it is important
    // to make sure to fetch id data again when the URL changes
    componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.key !== this.props.match.params.key) {
            this.setStates(nextProps.match.params.key);
        }
    }

    pageOnChange = (number)  => {
        this.setStates(number);
        /* Wonky parser but should work */
        const location = document.location.href;
        const split_string = location.split('/');
        const report_item = split_string[split_string.length - 1];
        if (number !== report_item) {
            this.props.history.push('/report/' + number);
        }
        this.props.setGlobalState({hacky_scroll: 'thank U next'});
    };

    setStates(key){
        const num = Number(key);
        this.setState({ currentPage: num });
        // set global state too
        this.props.setGlobalState({ currentPage: num });
        // If scroller isn't null
        if (this._pageScroller !== null){
            this._pageScroller.goToPage(num - 1);
        }
    }

    updateLanguage = (language) => {
        this.props.setGlobalState(prevGlobalState => ({
            currentLanguage: language
        }));
    }

    languageButtons = () =>
        <div className="languageButtons">
            <p className="spanish" id={this.props.globalState.currentLanguage === 'spanish' ? 'active' : ''} onClick={() => this.updateLanguage('spanish')}>Es</p>
            <p className="slash"> / </p>
            <p className="english" id={this.props.globalState.currentLanguage === 'english' ? 'active' : ''} onClick={() => this.updateLanguage('english')}>En</p>
        </div>

  creditText() {
      if (this.props.globalState.currentLanguage === 'english'){
        return (<div className="creditText">
            A project by <a href="https://www.gieinicaragua.org" target="_blank" rel="noopener noreferrer">GIEI</a>, <a href="https://www.eaaf.org" target="_blank" rel="noopener noreferrer">EAAF</a>, and <a href="http://www.situ.nyc" target="_blank" rel="noopener noreferrer">SITU</a>
        </div>)
        } else {
          return (<div className="creditText">
              Un proyecto de <a href="https://www.gieinicaragua.org" target="_blank" rel="noopener noreferrer">GIEI</a>, <a href="https://www.eaaf.org" target="_blank" rel="noopener noreferrer">EAAF</a>, y <a href="http://www.situ.nyc" target="_blank" rel="noopener noreferrer">SITU</a>
          </div>)
        }
      }

  //update global state for mobile event chapter toggle between event chapter text and carousel (and pass it to arrow partials)
  resetEventViewforMobileNext(){
    this.props.setGlobalState({ mobile_carousel: true });
    this.props.setGlobalState({ active_index: 0 });
  }

  resetEventViewforMobilePrev(){
    this.props.setGlobalState({ mobile_carousel: true });
    this.props.setGlobalState({ active_index: 0 });
    if (this.props.globalState.currentPage === 1) {
      this.props.setGlobalState({landingVideo: true});
    }
  }

  mobileDisclaimerText(){
    if (this.props.globalState.currentPage === 1) {
      return <CuidadoModal isOpen={this.props.globalState.cuidado_modal_open} />
    }
  }

  makeSureDataLoads(){
    this.props.setGlobalState({ })
  }

  mapOrNah() {
    const props = {};
    if (this.props.globalState.landingVideo !== true) {
      return (<Map {...props}
            viewport={viewport}
            currentLanguage={this.state.currentLanguage}
            currentPage={this.state.currentPage}
            mapLoaded={this.state.mapLoaded}/>
      )} else {
        return null;
      }
    }

    renderReport() {
        const props = {};
        props.currentPage = this.state.currentPage;
        // console.log(this.state.mapLoaded);
            return (
              <>
              {isMobile ? this.mobileDisclaimerText():null}
                  <div className="platformContainer">
                    {this.languageButtons()}
                    <ArchiveButton {...props}
                      currentPage={this.state.currentPage}
                      currentLanguage={this.state.currentLanguage} />
                    <HomeButton />
                    {this.props.globalState.mapLoaded && <ArrowPrev {...props}
                    handleClick = {() => this.resetEventViewforMobilePrev} />}
                    {this.props.globalState.mapLoaded && <ArrowNext {...props}
                    handleClick = {() => this.resetEventViewforMobileNext} />}
                    {this.mapOrNah()}
                    {this.props.globalState.mapLoaded && <ScrollNav {...props} />}
                    <a className="tweetButton" href="https://twitter.com/intent/tweet?text=Digital+platform+by+%23GIEI+of+%40IACHumanRights%2C+%40eaafoficial+and+%40SITU_Research+reveals+the+violent+repression+of+public+protests+in+%23Nicaragua+through+the+presentation+of+four+events+between+April+18th+-+May+30th,+2018%3A+http%3A%2F%2Fgieinicaragua-cartografia-violencia.org"
                      rel="noopener noreferrer"><img src={Tweet_icon} alt="tweetIcon"/></a>
                    <AboutButton {...props} />
                    <div className="scrollContainer">
                      <Landing {...props}></Landing>
                      <Event {...props}></Event>
                      <EventLanding {...props}></EventLanding>
                      <Carousel {...props}
                        mediaData={this.props.globalState.mediaData}
                        currentPage={this.state.currentPage} />
                      <Archive {...props}></Archive>
                      <About {...props}></About>
                    </div>
                    {this.creditText()}
                  </div>
                </>
            );
    }

    render() {
      if (this.state.currentPage === null) {
            return null
        } else {
            return (
              <>
                {this.renderReport()}
              </>
            )
        }
    }
}
export default withGlobalState(Report);
