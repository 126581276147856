import React from 'react';
import '../App.css';
import { Link } from 'react-router-dom';

function AboutButton(props) {

    return (<div className="AboutButton"><Link to='/28' className={`aboutButton ${props.currentPage === 28 ? 'active' : ''}`}>i</Link></div>)
}

export default AboutButton;
