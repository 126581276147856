import React from 'react';
import { withGlobalState } from 'react-globally';
import { Link } from 'react-router-dom';

class ScrollNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: this.props.globalState.currentPage,
      currentLanguage: this.props.globalState.currentLanguage,
      data: this.props.globalState.data,
    };
  }

  handleClick(page) {
    this.props.goToPage(page);
  }

  dataRender(pageData, language){
    return(<div className="pageName">
    <p className="page">{pageData[language].Page_Title}</p>
    </div>)
  }

  pageNameRender() {
     const globalData = this.props.globalState.data.data;
     const currentLanguage = this.props.globalState.currentLanguage;
     if (globalData !== undefined){
       const allChapterData = globalData[this.props.globalState.currentPage - 1];
       const chapterData = allChapterData[Object.keys(allChapterData)[0]];
         return this.dataRender(chapterData, currentLanguage);
     }
   }

render(){
  const currentPage = this.props.globalState.currentPage;
  return (
    <div className="scrollNavContainer">
      <h1>{this.pageNameRender()}</h1>
    <div className='scrollNav' id='scrollNav-right'>
        <Link to='/1' className={`circle ${currentPage <= 1 ? 'active' : ''}`}></Link>
        <Link to='/2' className={`circle ${currentPage > 1 && currentPage < 10 ? 'active' : ''}`} ></Link>
        <Link to='/10' className={`circle ${currentPage >= 10 && currentPage < 15 ? 'active' : ''}`}></Link>
        <Link to='/15' className={`circle ${currentPage >= 15 && currentPage < 21 ? 'active' : ''}`}></Link>
        <Link to='/21' className={`circle ${currentPage >= 21 && currentPage < 27 ? 'active' : ''}`}></Link>
        <Link to='/27' className={`circle ${currentPage === 27 ? 'active' : ''}`}></Link>
    </div>
  </div>
  )
}
}

export default withGlobalState(ScrollNav);
