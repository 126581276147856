import React from 'react';
import { withGlobalState } from 'react-globally';
import GIEILogo from '../images/giei_black.png';
import SituLogo from '../images/situ_black.png';
import EAAFLogo from '../images/eaaf_black.png';

class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: this.props.currentPage,
      currentLanguage: this.props.globalState.currentLanguage,
      data: this.props.globalState.data,
      is_archive: false,
    };
  }

  replace_orgs_with_links(text){
      let regex_GIEI = /GIEI/g;
      let regex_SITU = /SITU/g;
      let regex_EAAF = /EAAF/g;
      let linked_text = text.replace(regex_GIEI, "<a href='http://www.gieinicaragua.org'>GIEI</a>");
      linked_text = linked_text.replace(regex_SITU, "<a href='https://situ.nyc/'>SITU</a>");
      linked_text = linked_text.replace(regex_EAAF, "<a href='https://www.eaaf.org/'>EAAF</a>");
      return linked_text
  }
  renderAboutText() {
    const currentLanguage = this.props.globalState.currentLanguage;
    const aboutData = this.props.globalState.data.data[27].about;
    switch(currentLanguage) {
      case 'english':
        return <div className="about">
            <h3 className="aboutText">Project by</h3>
            <div className="aboutLogoContainer">
              <a className="GIEILogo" href="https://www.gieinicaragua.org" target="_blank" rel="noopener noreferrer"><img className="aboutGieiLogo" src={GIEILogo} alt="SituLogo"/></a>
              <a className="EAAFLogo" href="https://www.eaaf.org" target="_blank" rel="noopener noreferrer"><img className="aboutEAAFLogo" src={EAAFLogo} alt="SituLogo"/></a>
              <a className="SituLogo" href="http://www.situ.nyc" target="_blank" rel="noopener noreferrer"><img className="aboutSituLogo" src={SituLogo} alt="SituLogo"/></a>
              <h3 className="aboutText">Methodology & Information</h3>
            </div>
            <p className = "aboutBody" dangerouslySetInnerHTML={{__html:this.replace_orgs_with_links(aboutData.english.About_Info)}}></p>
          </div>;
      case 'spanish':
        return <div className="about">
            <h3 className="aboutText">Proyecto de</h3>
            <div className="aboutLogoContainer">
              <a className="GIEILogo" href="https://www.gieinicaragua.org" target="_blank" rel="noopener noreferrer"><img className="aboutGieiLogo" src={GIEILogo} alt="GIEILogo"/></a>
              <a className="EAAFLogo" href="https://www.eaaf.org" target="_blank" rel="noopener noreferrer"><img className="aboutEAAFLogo" src={EAAFLogo} alt="EAAFLogo"/></a>
              <a className="SituLogo" href="http://www.situ.nyc" target="_blank" rel="noopener noreferrer"><img className="aboutSituLogo" src={SituLogo} alt="SituLogo"/></a>
                <h3 className="aboutText">Metodología & Información</h3>
            </div>
            <p className = "aboutBody" dangerouslySetInnerHTML={{__html:this.replace_orgs_with_links(aboutData.spanish.About_Info)}}></p>
          </div>
      default: return null
    }
  }

  render () {
    if (this.props.currentPage !== 28){
      return null
    } else {
      return (
        <div className="aboutScroll">
          <div className="aboutComponent">
            <div className="aboutTitle"></div>
            {this.renderAboutText()}
          </div>
        </div>
      )
    }
  }
}

export default withGlobalState(About);
