import React from 'react';
import './App.css';
import { withGlobalState } from 'react-globally';
import { Switch, Route, Redirect } from 'react-router-dom';
import hacks from 'viewport-units-buggyfill/viewport-units-buggyfill.hacks';
import media_master from './jsons/media_master.json';
import Report from './components/Report';
import report_object from './jsons/report_object.json';
import ViewportUnitsBuggyfill from 'viewport-units-buggyfill';

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentLanguage: props.globalState.currentLanguage,
            mediaData: props.globalState.mediaData,
            orientation: ''
        };
        this.handleOrientationChange = this.handleOrientationChange.bind(this);
    }

    componentDidMount() {
        document.title = "GIEI Nicaragua Cartografía Violencia";
        window.addEventListener("resize", this.handleOrientationChange);
        this.updateLanguage();
    };

    componentWillMount() {
        this.props.setGlobalState({
            data: report_object,
            dataReceived: true
        });
        this.props.setGlobalState({
            mediaData: media_master,
            mediaDataReceived: true
        });
        this.handleOrientationChange();
    };

    componentWillUnmount() {
      window.removeEventListener("resize", this.handleOrientationChange);
    }

    handleOrientationChange() {
      if (window.innerHeight > window.innerWidth){
        ViewportUnitsBuggyfill.init({hacks: hacks});
        console.log('portrait')
        this.forceUpdate()
      } else {
        this.forceUpdate()
        console.log('landscape')
      }
    }

    updateLanguage(){
      let location = document.location.href;
      let regexHref = location.match(/#(.*)/);
      console.log(regexHref[0])
      if (regexHref[0] === '#/es') {
        this.props.setGlobalState({
          currentLanguage: 'spanish'
        })
      }
    }

    render() {
        if (!this.props.globalState.dataReceived || !this.props.globalState.mediaDataReceived) {
            return null
        } else {
            return (
                <>
                    <Switch>
                      <Route exact path = '/' render={() => (<Redirect to= "/1"/>)} />
                      <Route exact path = '/es' render={() => (<Redirect to= "/1"/>)} />
                      <Route path = '/:key' component = { Report } />
                    </Switch>
                </>
            )
        }
    }
}

export default withGlobalState(Main);
