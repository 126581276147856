import React, {Component} from 'react';
import ReactMapGL, {FlyToInterpolator} from 'react-map-gl';
import { withGlobalState } from 'react-globally';
import * as d3 from 'd3-ease';
import geoViewport from '@mapbox/geo-viewport';
import Legends from '../partials/Legends';
import {isMobile} from 'react-device-detect';


const TOKEN = process.env.REACT_APP_MAPBOX_API_KEY;

const navStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  padding: '10px'
};

// mapbox heatmap stuff
const pagenum2eventcodes = {
    1:"0.1",
    2:"1",
    3:"1.1.1",
    4:"1.2.1",
    5:"1.2.2",
    6:"1.2.3",
    7:"1.2.4",
    8:"1.3.1",
    9:"1.3.2",
    15:"2",
    16:"2.1.1",
    17:"2.1.2",
    18:"2.1.3",
    19:"2.1.4",
    20:"2.1.5",
    10:"3",
    11:"3.1.1",
    12:"3.1.2",
    13:"3.1.3",
    14:"3.1.4",
    21:"4",
    22:"4.1.1",
    23:"4.1.2",
    24:"4.1.3",
    25:"4.1.4",
    26:"4.1.5",
    27:"5",
    28:"6"
}

var eventcode2viewport;
if (!isMobile){
    eventcode2viewport = {
      "0.1": {
          latitude: 12.8654,
          longitude: -83.207,
          west: -92.43896484375,
          south: 8.341953075466817,
          east: -73.98193359375,
          north: 17.308687886770034,
          zoom: 7,
          transitionDuration: 6000,
          layers_on: [{
                  id: "deaths",
                  time_on: 0,
                  time_off: null,
              },
              {
                  source: "situmapping.5vpjocdg",
                  id: "diagram_loc_camino",
                  time_on: 0,
                  time_off: null,
              },
              {
                  source: "situmapping.5lmpviw7",
                  id: "diagram_loc_uni",
                  time_on: 1500,
                  time_off: null,
              },
              {
                  source: "situmapping.3x2yf7tr",
                  id: "diagram_loc_esteli",
                  time_on: 3000,
                  time_off: null,
              },
              {
                  source: "situmapping.7mt9djko",
                  id: "diagram_loc_marcha",
                  time_on: 4500,
                  time_off: null,
              },
          ]
      },


        "1": {
            latitude: 12.788698655987275,
            longitude: -85.79068376133449,
            west: -94.62447078854247,
            south: 6.742323178254124,
            east: -76.12857659391645,
            north: 18.839127886086626,
            zoom: 7.1,
            transitionDuration: 6000,
            layers_on:[
                {
                    source: "situmapping.1z5c42rx",
                    id: "diagram_1",
                    time_on: 0,
                    time_off: null,
                }
            ]
        },

        "1.1.1": {
            latitude: 12.438048646204331,
            longitude: -86.88127009034791,
            west:-86.88681776391002,
            south:12.435346348173473,
            east:-86.8757224167858,
            north:12.440750916124811,
            zoom: 17.7,
            transitionDuration: 6000,
            layers_on:[

                {
                    source: "situmapping.4v19tkig",
                    id: "diagram_1.1.1",
                    time_on: 0,
                    time_off:null
                },
            ]
        },
        "1.2.1": {
            latitude: 12.110707284402102,
            longitude: -86.25287333255584,
            west: -86.25439542250973,
            south: 12.10963144831435,
            east: -86.251136625246,
            north: 12.111783601541779,
            zoom: 19.73,
            transitionDuration: 6000,
            layers_on:[

                {
                    source: "situmapping.5cz302us",
                    id: "diagram_1.2.1",
                    time_on: 0,
                    time_off:null
                },
            ]
        },
        "1.2.2": {
            latitude: 12.110707284402102,
            longitude: -86.25287333255584,
            west: -86.25439542250973,
            south: 12.10963144831435,
            east: -86.251136625246,
            north: 12.111783601541779,
            zoom: 19.73,
            transitionDuration: 2000,
            layers_on:[

                {
                    source: "situmapping.9b1f94lr",
                    id: "diagram_1.2.2",
                    time_on: 0,
                    time_off:null
                },
            ]
        },
        "1.2.3": {
            latitude: 12.110707284402102,
            longitude: -86.25287333255584,
            west: -86.25439542250973,
            south: 12.10963144831435,
            east: -86.251136625246,
            north: 12.111783601541779,
            zoom: 19.73,
            transitionDuration: 2000,
            layers_on:[

                {
                    source: "situmapping.ckcu6yib",
                    id: "diagram_1.2.3",
                    time_on: 0,
                    time_off:null
                },
            ]
        },
        "1.2.4": {
            latitude: 12.11166925105858,
            longitude: -86.25200391865157,
            west: -86.26044169324524,
            south: 12.10661928685354,
            east: -86.24389417702174,
            north: 12.117552262702786,
            zoom: 16.7,
            transitionDuration: 4000,
            layers_on:[

                {
                    source: "situmapping.4jrgadgc",
                    id: "diagram_1.2.4",
                    time_on: 0,
                    time_off:null
                },
            ]
        },
        "1.3.1": {
            latitude: 12.126935459997213,
            longitude: -86.2701471595612,
            west: -86.27449977601836,
            south: 12.124812774597595,
            east: -86.26579454310406,
            north: 12.12905812849914,
            zoom: 18.05,
            transitionDuration: 8000,
            layers_on:[

                {
                    source: "situmapping.2h3zyyq3",
                    id: "diagram_1.3.1",
                    time_on: 0,
                    time_off:3000,
                },
                {
                    source: "situmapping.7g1l4t7o",
                    id: "diagram_1.3.2.1",
                    time_on: 3000,
                    time_off:null
                },
            ]
        },
        "1.3.2": {
            latitude: 12.126935459997213,
            longitude: -86.2701471595612,
            west: -86.27408938726651,
            south: 12.124368346151194,
            east: -86.26538394263255,
            north: 12.130117181766284,
            zoom: 18.05,
            transitionDuration: 8000,
            layers_on:[


                {
                    source: "situmapping.c0b22fp2",
                    id: "diagram_1.3.2.2",
                    time_on: 0,
                    time_off:null
                },
            ]
        },

        "2": {
            latitude: 13.081487835620315,
            longitude: -86.33791781592669,
            west: -86.48321321718768,
            south: 12.98324678154375,
            east: -86.1869362906043,
            north: 13.178173111069368,
            zoom: 13.163757910405257,
            transitionDuration: 8000,
            layers_on: [
                {
                    source: "situmapping.0lucqh4p",
                    id: "diagram_2",
                    time_on: 0,
                    time_off: null,
                }
            ]
        },
        "2.1.1": {
            latitude: 13.093529685129184,
            longitude: -86.35154444782862,
            west: -86.37532784503931,
            south: 13.081974458711143,
            east: -86.32776105061791,
            north: 13.10508436954422,
            zoom: 15.6,
            transitionDuration: 8000,
            layers_on:[

                {
                    source: "situmapping.aozi6ywx",
                    id: "diagram_2.1.1.1",
                    time_on: 0,
                    time_off:2500
                },
                {
                    source: "situmapping.937jn98x",
                    id: "diagram_2.1.1.2",
                    time_on: 2500,
                    time_off:5000
                },
                {
                    source: "situmapping.0crko4d6",
                    id: "diagram_2.1.1.3",
                    time_on: 5000,
                    time_off:null
                },
            ]
        },
        "2.1.2": {
            latitude: 13.094936305436974,
            longitude: -86.35221758324768,
            west: -86.36903498469502,
            south: 13.08676562922232,
            east: -86.33540018180034,
            north: 13.103106710623091,
            zoom: 16.1,
            transitionDuration: 8000,
            layers_on:[

                {
                    source: "situmapping.d7al26rs",
                    id: "diagram_2.1.2",
                    time_on: 0,
                    time_off: null
                }
            ]
        },
        "2.1.3": {
            latitude: 13.093049068661797,
            longitude: -86.35595195859698,
            west: -86.35912899830183,
            south: 13.090791095042619,
            east: -86.35229908832153,
            north: 13.095284402778884,
            zoom: 18.4,
            transitionDuration: 8000,
            layers_on:[

                {
                    source: "situmapping.djbh3yr3",
                    id: "diagram_2.1.3",
                    time_on: 0,
                    time_off: null
                }
            ]
        },
        "2.1.4": {
            latitude: 13.080498608141989,
            longitude: -86.33319041363691,
            west: -86.41774920781295,
            south: 13.039410866395905,
            east: -86.24863161946087,
            north: 13.12157950495109,
            zoom: 13.77,
            transitionDuration: 8000,
            layers_on:[

                {
                    source: "situmapping.b4wjvzb8",
                    id: "diagram_2.1.4.1",
                    time_on: 0,
                    time_off:3000
                },

                {
                    source: "situmapping.df2zwt1u",
                    id: "diagram_2.1.4.2",
                    time_on: 3000,
                    time_off:null
                },
            ]
        },
        "2.1.5": {
            latitude: 13.092813164649113,
            longitude: -86.35568038128554,
            // west: -86.35912899830183,
            // south: 13.090791095042619,
            // east: -86.35229908832153,
            // north: 13.095284402778884,
            west: -86.35968854617389,
            south: 13.090382945357346,
            east: -86.35135714813993,
            north: 13.095866483691951,
            zoom: 17.8,
            transitionDuration: 8000,
            layers_on:[

                {
                    source: "situmapping.dtru8q0z",
                    id: "diagram_2.1.5",
                    time_on: 0,
                    time_off: null
                }
            ]
        },
        "3": {
            latitude: 12.131128710940066,
            longitude: -86.2646004504962,
            west: -86.31401957177448,
            south: 12.098484884030055,
            east: -86.21369791636792,
            north: 12.164733450188272,
            zoom: 14.827081302761913,
            transitionDuration: 8000,
            layers_on: [
                {
                    source: "situmapping.43li3zwu",
                    id: "diagram_3",
                    time_on: 0,
                    time_off: null,
                }
            ]

        },

        "3.1.1": {
            latitude: 12.131274299933171,
            longitude: -86.25250707874785,
            west: -86.30143998592067,
            south: 12.107410099783579,
            east: -86.20357417157504,
            north:  12.155136363722074,
            zoom: 14.559149805912519,
            transitionDuration: 2000,
            layers_on:[

                {
                    source: "situmapping.2r4qe7x5",
                    id: "diagram_3.1.1.1",
                    time_on: 0,
                    time_off:3000
                },
                {
                    source: "situmapping.4d8vfutz",
                    id: "diagram_3.1.1.2",
                    time_on: 3000,
                    time_off:null
                },
            ]
        },
        "3.1.2": {
            latitude: 12.131274299933171,
            longitude: -86.25250707874785,
            west: -86.28812580199843,
            south: 12.113316153795077,
            east: -86.23457538961202,
            north: 12.148694504735436,
            zoom: 14.559149805912519,
            transitionDuration: 2000,
            layers_on:[

                {
                    source: "situmapping.6fvh6zy4",
                    id: "diagram_3.1.2.1",
                    time_on: 0,
                    time_off:null
                },
            ]
        },
        "3.1.3":  {
            latitude: 12.131274299933171,
            longitude: -86.25250707874785,
            west: -86.28812580199843,
            south: 12.113316153795077,
            east: -86.23457538961202,
            north: 12.148694504735436,
            zoom: 14.559149805912519,
            transitionDuration: 2000,
            layers_on:[

                {
                    source: "situmapping.5aeetz2u",
                    id: "diagram_3.1.3",
                    time_on: 0,
                    time_off: null
                },
            ]
        },
        "3.1.4": {
            latitude: 12.131274299933171,
            longitude: -86.25250707874785,
            west: -86.28812580199843,
            south: 12.113316153795077,
            east: -86.23457538961202,
            north: 12.148694504735436,
            zoom: 14.559149805912519,
            transitionDuration: 2000,
            layers_on:[

                {
                    source: "situmapping.7lo8qaai",
                    id: "diagram_3.1.4",
                    time_on: 0,
                    time_off: null
                },
            ]
        },
        "4": {
            latitude: 12.125270301802574,
            longitude: -86.24968782474495,
            west: -86.35513377913324,
            south: 12.073841316929308,
            east:  -86.14424187035667,
            north: 12.17668937081828,
            zoom: 13.45152296411589,
            transitionDuration: 8000,
            layers_on:[
                {
                    source: "situmapping.ad57m5zt",
                    id: "diagram_4",
                    time_on: 0,
                    time_off: null,
                }
            ]
        },

        "4.1.1": {
            latitude: 12.121603844882761,
            longitude: -86.25439293041455,
            west: -86.30242326119692,
            south: 12.088262562455661,
            east: -86.19887131467435,
            north: 12.156646636196113,
            zoom: 14.89,
            transitionDuration: 2000,
            layers_on:[

                {
                    source: "situmapping.b0fikp3r",
                    id: "diagram_4.1.1",
                    time_on: 0,
                    time_off: null
                },
            ]
        },
        "4.1.2": {
            latitude: 12.131344640964238,
            longitude: -86.26210335705595,
            west: -86.29348573782052,
            south: 12.116039946220612,
            east:  -86.23072097629137,
            north: 12.146648456995605,
            zoom: 15.2,
            transitionDuration: 2000,
            layers_on:[

                {
                    source: "situmapping.bzppja7f",
                    id: "diagram_4.1.2",
                    time_on: 0,
                    time_off: null
                },

            ]
        },
        "4.1.3": {
            latitude: 12.131344640964238,
            longitude: -86.26210335705595,
            west: -86.29348573782052,
            south: 12.116039946220612,
            east:  -86.23072097629137,
            north: 12.146648456995605,
            zoom: 15.2,
            transitionDuration: 2000,
            layers_on:[

                {
                    source: "situmapping.bzppja7f",
                    id: "diagram_4.1.3",
                    time_on: 0,
                    time_off: null
                },
            ]
        },
        "4.1.4": {
            latitude: 12.131344640964238,
            longitude: -86.26210335705595,
            west: -86.29348573782052,
            south: 12.116039946220612,
            east:  -86.23072097629137,
            north: 12.146648456995605,
            zoom: 15.2,
            transitionDuration: 2000,
            layers_on:[
                {
                    source: "situmapping.1irzwj3b",
                    id: "diagram_4.1.4",
                    time_on: 0,
                    time_off: null
                },
            ]
        },


        "4.1.5":{
            latitude: 12.131344640964238,
            longitude: -86.26210335705595,
            west: -86.27941066766603,
            south: 12.120419183417702,
            east: -86.24947819813526,
            north: 12.140194272951003,
            zoom: 15.2,
            transitionDuration: 2000,
            layers_on:[
                {
                    source: "situmapping.4bco3gtu",
                    id: "diagram_4.1.5",
                    time_on: 0,
                    time_off: null
                },
            ]
        },



        "5": {
            latitude: 12.8654,
              longitude: -83.207,
              west: -92.43896484375,
              south: 8.341953075466817,
              east: -73.98193359375,
              north: 17.308687886770034,
              zoom: 7,
            transitionDuration: 8000,
            layers_on:[
            ]
        },
        "6": {
            latitude: 12.8654,
              longitude: -83.207,
              west: -92.43896484375,
              south: 8.341953075466817,
              east: -73.98193359375,
              north: 17.308687886770034,
              zoom: 7,
            transitionDuration: 6000,
            layers_on: [{
                    id: "deaths",
                    time_on: 0,
                    time_off: null,
                },
                {
                    source: "situmapping.5vpjocdg",
                    id: "diagram_loc_camino",
                    time_on: 0,
                    time_off: null,
                },
                {
                    source: "situmapping.5lmpviw7",
                    id: "diagram_loc_uni",
                    time_on: 1500,
                    time_off: null,
                },
                {
                    source: "situmapping.3x2yf7tr",
                    id: "diagram_loc_esteli",
                    time_on: 3000,
                    time_off: null,
                },
                {
                    source: "situmapping.7mt9djko",
                    id: "diagram_loc_marcha",
                    time_on: 4500,
                    time_off: null,
                },
            ]
        }
    };
} else {

    eventcode2viewport = {
      "0.1": {
          latitude: 12.8654,
          longitude: -83.207,
          west: -90.14944865144717,
          south: 8.986267724504605,
          east: -79.90614331597313,
          north: 16.10039843345421,
          zoom: 7,
          transitionDuration: 6000,
          layers_on: [
              {
                  source: "situmapping.0iilk4ps",
                  id: "diagram_loc_camino",
                  time_on: 0,
                  time_off: null,
              },
              {
                  source: "situmapping.bu50zmz9",
                  id: "diagram_loc_uni",
                  time_on: 1500,
                  time_off: null,
              },
              {
                  source: "situmapping.ausv7ije",
                  id: "diagram_loc_esteli",
                  time_on: 3000,
                  time_off: null,
              },
              {
                  source: "situmapping.dgl133sb",
                  id: "diagram_loc_marcha",
                  time_on: 4500,
                  time_off: null,
              },
          ]
      },


        "1": {
            latitude: 12.788698655987275,
            longitude: -85.79068376133449,
            west: -91.81523431887658,
            south: 8.05297104061936,
            east: -83.79292103674553,
            north: 16.838927449218023,
            zoom: 7.1,
            transitionDuration: 6000,
            layers_on:[
                {
                    source: "situmapping.1z5c42rx",
                    id: "diagram_1",
                    time_on: 0,
                    time_off: null,
                }
            ]
        },

        "1.1.1": {
            latitude: 12.438048646204331,
            longitude: -86.88127009034791,
            west: -86.88499317790843,
            south: 12.434828024916818,
            east: -86.87912299272892,
            north: 12.441264112678986,
            zoom: 17.7,
            transitionDuration: 6000,
            layers_on:[

                {
                    source: "situmapping.4v19tkig",
                    id: "diagram_1.1.1",
                    time_on: 0,
                    time_off:null
                },
            ]
        },
        "1.2.1": {
            latitude: 12.110707284402102,
            longitude: -86.25287333255584,
            west: -86.25395604333862,
            south: 12.109600636131997,
            east: -86.2522465974978,
            north: 12.111477209726116,
            zoom: 19.73,
            transitionDuration: 6000,
            layers_on:[

                {
                    source: "situmapping.5cz302us",
                    id: "diagram_1.2.1",
                    time_on: 0,
                    time_off:null
                },
            ]
        },
        "1.2.2": {
            latitude: 12.110707284402102,
            longitude: -86.25287333255584,
            west: -86.25395604333862,
            south: 12.109600636131997,
            east: -86.2522465974978,
            north: 12.111477209726116,
            zoom: 19.73,
            transitionDuration: 2000,
            layers_on:[

                {
                    source: "situmapping.9b1f94lr",
                    id: "diagram_1.2.2",
                    time_on: 0,
                    time_off:null
                },
            ]
        },
        "1.2.3": {
            latitude: 12.110707284402102,
            longitude: -86.25287333255584,
            west: -86.25395604333862,
            south: 12.109600636131997,
            east: -86.2522465974978,
            north: 12.111477209726116,
            zoom: 19.73,
            transitionDuration: 2000,
            layers_on:[

                {
                    source: "situmapping.ckcu6yib",
                    id: "diagram_1.2.3",
                    time_on: 0,
                    time_off:null
                },
            ]
        },
        "1.2.4": {
            latitude: 12.11166925105858,
            longitude: -86.25200391865157,
            west: -86.2576392031053,
            south: 12.108585846081423,
            east: -86.25063593840774,
            north: 12.116273745989538,
            zoom: 16.7,
            transitionDuration: 4000,
            layers_on:[

                {
                    source: "situmapping.4jrgadgc",
                    id: "diagram_1.2.4",
                    time_on: 0,
                    time_off:null
                },
            ]
        },
        "1.3.1": {
            latitude: 12.126935459997213,
            longitude: -86.2701471595612,
            west: -86.27259401892454,
            south: 12.125059007510295,
            east: -86.26949087441335,
            north: 12.128465330702252,
            zoom: 18.05,
            transitionDuration: 8000,
            layers_on:[

                {
                    source: "situmapping.2h3zyyq3",
                    id: "diagram_1.3.1",
                    time_on: 0,
                    time_off:3000,
                },
                {
                    source: "situmapping.7g1l4t7o",
                    id: "diagram_1.3.2.1",
                    time_on: 3000,
                    time_off:null
                },
            ]
        },
        "1.3.2": {
            latitude: 12.126935459997213,
            longitude: -86.2701471595612,
            west: -86.27280485550789,
            south: 12.125223542412508,
            east: -86.26910491993779,
            north: 12.12928495593566,
            zoom: 18.05,
            transitionDuration: 8000,
            layers_on:[


                {
                    source: "situmapping.c0b22fp2",
                    id: "diagram_1.3.2.2",
                    time_on: 0,
                    time_off:null
                },
            ]
        },

        "2": {
            latitude: 13.081487835620315,
            longitude: -86.33791781592669,
            west: -86.45166289198748,
            south: 13.002362715403876,
            east: -86.2972099825405,
            north: 13.171271486480158,
            zoom: 13.163757910405257,
            transitionDuration: 8000,
            layers_on: [
                {
                    source: "situmapping.0lucqh4p",
                    id: "diagram_2",
                    time_on: 0,
                    time_off: null,
                }
            ]
        },
        "2.1.1": {
            latitude: 13.093529685129184,
            longitude: -86.35154444782862,
            west: -86.37275577558111,
            south: 13.081955623552906,
            east: -86.33829927713099,
            north: 13.10585079350912,
            zoom: 15.6,
            transitionDuration: 8000,
            layers_on:[

                {
                    source: "situmapping.aozi6ywx",
                    id: "diagram_2.1.1.1",
                    time_on: 0,
                    time_off:2500
                },
                {
                    source: "situmapping.937jn98x",
                    id: "diagram_2.1.1.2",
                    time_on: 2500,
                    time_off:5000
                },
                {
                    source: "situmapping.0crko4d6",
                    id: "diagram_2.1.1.3",
                    time_on: 5000,
                    time_off:null
                },
            ]
        },
        "2.1.2": {
            latitude: 13.094936305436974,
            longitude: -86.35221758324768,
            west: -86.36404411131248,
            south: 13.085957526041716,
            east: -86.34724459984731,
            north: 13.104328757020665,
            zoom: 16.1,
            transitionDuration: 8000,
            layers_on:[

                {
                    source: "situmapping.d7al26rs",
                    id: "diagram_2.1.2",
                    time_on: 0,
                    time_off: null
                }
            ]
        },
        "2.1.3": {
            latitude: 13.093049068661797,
            longitude: -86.35595195859698,
            west: -86.35853193880627,
            south: 13.0909198334422,
            east: -86.35468778866093,
            north: 13.095123668708482,
            zoom: 18.4,
            transitionDuration: 8000,
            layers_on:[

                {
                    source: "situmapping.djbh3yr3",
                    id: "diagram_2.1.3",
                    time_on: 0,
                    time_off: null
                }
            ]
        },
        "2.1.4": {
            latitude: 13.080498608141989,
            longitude: -86.33319041363691,
            west: -86.40490865725114,
            south: 13.043554136288,
            east: -86.3001855702169,
            north: 13.116182476625472,
            zoom: 13.77,
            transitionDuration: 8000,
            layers_on:[

                {
                    source: "situmapping.b4wjvzb8",
                    id: "diagram_2.1.4.1",
                    time_on: 0,
                    time_off:3000
                },

                {
                    source: "situmapping.df2zwt1u",
                    id: "diagram_2.1.4.2",
                    time_on: 3000,
                    time_off:null
                },
            ]
        },
        "2.1.5": {
            latitude: 13.092813164649113,
            longitude: -86.35568038128554,
            west: -86.35836083676912,
            south: 13.091096093809119,
            east: -86.3546812341554,
            north: 13.095119983935938,
            zoom: 17.8,
            transitionDuration: 8000,
            layers_on:[

                {
                    source: "situmapping.dtru8q0z",
                    id: "diagram_2.1.5",
                    time_on: 0,
                    time_off: null
                }
            ]
        },
        "3": {
            latitude: 12.131128710940066,
            longitude: -86.2646004504962,
            west: -86.29900967798945,
            south: 12.106820446964818,
            east: -86.25338236596343,
            north: 12.15690460894684,
            zoom: 14.827081302761913,
            transitionDuration: 8000,
            layers_on: [
                {
                    source: "situmapping.43li3zwu",
                    id: "diagram_3",
                    time_on: 0,
                    time_off: null,
                }
            ]

        },

        "3.1.1": {
            latitude: 12.131274299933171,
            longitude: -86.25250707874785,
            west: -86.28343935314675,
            south: 12.106981065032235,
            east: -86.24321990841648,
            north: 12.151129587569992,
            zoom: 14.559149805912519,
            transitionDuration: 2000,
            layers_on:[

                {
                    source: "situmapping.2r4qe7x5",
                    id: "diagram_3.1.1.1",
                    time_on: 0,
                    time_off:3000
                },
                {
                    source: "situmapping.4d8vfutz",
                    id: "diagram_3.1.1.2",
                    time_on: 3000,
                    time_off:null
                },
            ]
        },
        "3.1.2": {
            latitude: 12.131274299933171,
            longitude: -86.25250707874785,
            west: -86.28239821875654,
            south: 12.121358909635799,
            east: -86.25484232025735,
            north: 12.140540596460978,
            zoom: 14.559149805912519,
            transitionDuration: 2000,
            layers_on:[

                {
                    source: "situmapping.6fvh6zy4",
                    id: "diagram_3.1.2.1",
                    time_on: 0,
                    time_off:null
                },
            ]
        },
        "3.1.3":  {
            latitude: 12.131274299933171,
            longitude: -86.25250707874785,
            west: -86.28086125592971,
            south: 12.122437150518225,
            east: -86.25636234472371,
            north: 12.139490864723827,
            zoom: 14.559149805912519,
            transitionDuration: 2000,
            layers_on:[

                {
                    source: "situmapping.5aeetz2u",
                    id: "diagram_3.1.3",
                    time_on: 0,
                    time_off: null
                },
            ]
        },
        "3.1.4": {
            latitude: 12.131274299933171,
            longitude: -86.25250707874785,
            west: -86.27862468796441,
            south: 12.119133236950628,
            east: -86.25876687610503,
            north: 12.140930898906692,
            zoom: 14.559149805912519,
            transitionDuration: 2000,
            layers_on:[

                {
                    source: "situmapping.7lo8qaai",
                    id: "diagram_3.1.4",
                    time_on: 0,
                    time_off: null
                },
            ]
        },
        "4": {
            latitude: 12.125270301802574,
            longitude: -86.24968782474495,
            west: -86.32437395866245,
            south: 12.072759203449682,
            east: -86.22735402508191,
            north: 12.179258314417776,
            zoom: 13.45152296411589,
            transitionDuration: 8000,
            layers_on:[
                {
                    source: "situmapping.ad57m5zt",
                    id: "diagram_4",
                    time_on: 0,
                    time_off: null,
                }
            ]
        },

        "4.1.1": {
            latitude: 12.121603844882761,
            longitude: -86.25439293041455,
            west: -86.30278083418254,
            south: 12.09895104135488,
            east: -86.22289396668613,
            north: 12.15456123746804,
            zoom: 14.89,
            transitionDuration: 2000,
            layers_on:[

                {
                    source: "situmapping.b0fikp3r",
                    id: "diagram_4.1.1",
                    time_on: 0,
                    time_off: null
                },
            ]
        },
        "4.1.2": {
            latitude: 12.131344640964238,
            longitude: -86.26210335705595,
            west: -86.28625569214084,
            south: 12.119998135660772,
            east: -86.25185933157248,
            north: 12.143941382863703,
            zoom: 15.2,
            transitionDuration: 2000,
            layers_on:[

                {
                    source: "situmapping.bzppja7f",
                    id: "diagram_4.1.2",
                    time_on: 0,
                    time_off: null
                },

            ]
        },
        "4.1.3": {
            latitude: 12.131344640964238,
            longitude: -86.26210335705595,
            west: -86.28625569214084,
            south: 12.119998135660772,
            east: -86.25185933157248,
            north: 12.143941382863703,
            zoom: 15.2,
            transitionDuration: 2000,
            layers_on:[

                {
                    source: "situmapping.bzppja7f",
                    id: "diagram_4.1.3",
                    time_on: 0,
                    time_off: null
                },
            ]
        },
        "4.1.4": {
            latitude: 12.131344640964238,
            longitude: -86.26210335705595,
            west: -86.28625569214084,
            south: 12.119998135660772,
            east: -86.25185933157248,
            north: 12.143941382863703,
            zoom: 15.2,
            transitionDuration: 2000,
            layers_on:[
                {
                    source: "situmapping.1irzwj3b",
                    id: "diagram_4.1.4",
                    time_on: 0,
                    time_off: null
                },
            ]
        },


        "4.1.5":{
            latitude: 12.131344640964238,
            longitude: -86.26210335705595,
            west: -86.27394452463611,
            south: 12.125251123334731,
            east: -86.2613389812407,
            north: 12.13402589712852,
            zoom: 15.2,
            transitionDuration: 2000,
            layers_on:[
                {
                    source: "situmapping.4bco3gtu",
                    id: "diagram_4.1.5",
                    time_on: 0,
                    time_off: null
                },
            ]
        },



        "5": {
            latitude: 12.8654,
              longitude: -83.207,
              west: -90.14944865144717,
              south: 8.986267724504605,
              east: -79.90614331597313,
              north: 16.10039843345421,
              zoom: 6,
            transitionDuration: 8000,
            layers_on:[
            ]
        },
        "6": {
            latitude: 12.8654,
              longitude: -83.207,
              west: -90.14944865144717,
              south: 8.986267724504605,
              east: -79.90614331597313,
              north: 16.10039843345421,
              zoom: 6,
            transitionDuration: 6000,
            layers_on: [{
                    id: "deaths",
                    time_on: 0,
                    time_off: null,
                },
                {
                    source: "situmapping.5vpjocdg",
                    id: "diagram_loc_camino",
                    time_on: 0,
                    time_off: null,
                },
                {
                    source: "situmapping.5lmpviw7",
                    id: "diagram_loc_uni",
                    time_on: 1500,
                    time_off: null,
                },
                {
                    source: "situmapping.3x2yf7tr",
                    id: "diagram_loc_esteli",
                    time_on: 3000,
                    time_off: null,
                },
                {
                    source: "situmapping.7mt9djko",
                    id: "diagram_loc_marcha",
                    time_on: 4500,
                    time_off: null,
                },
            ]
        }
    };
}

var mediaData_geojson = {
    "type": "FeatureCollection",
    "features":[]
};
const layer_ids_to_toggle = [];
const timeouts = [];
const min_longitude= -90.3292436532345;
const max_longitude = -77.4417926629972;
var min_latitude = 10.566930151762332;
const max_latitude = 14.500388082661468;
var min_zoom = 6.5;
if (isMobile){
    min_latitude = 9;
    min_zoom = 5;
}
function distance(lat1, lon1, lat2, lon2, unit) {
	if ((lat1 === lat2) && (lon1 === lon2)) {
		return 0;
	}
	else {
		var radlat1 = Math.PI * lat1/180;
		var radlat2 = Math.PI * lat2/180;
		var theta = lon1-lon2;
		var radtheta = Math.PI * theta/180;
		var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
		if (dist > 1) {
			dist = 1;
		}
		dist = Math.acos(dist);
		dist = dist * 180/Math.PI;
		dist = dist * 60 * 1.1515;
		if (unit==="K") { dist = dist * 1.609344 }
		if (unit==="N") { dist = dist * 0.8684 }
		return dist;
	}
}


class Map extends Component {

    constructor(props) {
        super(props);

        let eventcode = pagenum2eventcodes[this.props.currentPage];
        let calc_viewport = geoViewport.viewport([
            eventcode2viewport[eventcode].west,
            eventcode2viewport[eventcode].south,
            eventcode2viewport[eventcode].east,
            eventcode2viewport[eventcode].north,
        ], [window.innerWidth, window.innerHeight], 0, 22, 256, true);

        this.state = {
            component: '',
            data: [],
            currentLanguage: this.props.currentLangauge,
            viewport: {latitude: calc_viewport.center[1],
                        longitude: calc_viewport.center[0],
                        zoom: calc_viewport.zoom*0.98,
                        },
            mapStyle: this.props.mapStyle,
            currentPage: this.props.currentPage,
            firstLoadComplete: false,
        };
    }

  componentDidMount() {
      window.addEventListener('resize', this._resize);

      this._resize();
      this.geojsonify_mediaData();
      this.setState({
          width: document.getElementsByClassName("map archiveMap")[0].offsetWidth,
          height: document.getElementsByClassName("map archiveMap")[0].offsetHeight
      })

      let pointer_to_map_component = this;

      const map_instance = this.mapRef.getMap();
      let eventcode_currentpage = pagenum2eventcodes[this.state.currentPage];

      map_instance.on('load', function() {
        pointer_to_map_component.props.setGlobalState({mapLoaded: true});
        pointer_to_map_component.setState({mapLoaded: true});

        // for each eventcode, for each layer on, add source and add as layer
        let eventcodes = Object.keys(eventcode2viewport);

        eventcodes.forEach(function(eventcode){

            //create viewports for transitions
            let calc_viewport = geoViewport.viewport([
                eventcode2viewport[eventcode].west,
                eventcode2viewport[eventcode].south,
                eventcode2viewport[eventcode].east,
                eventcode2viewport[eventcode].north,
            ], [pointer_to_map_component.state.width, pointer_to_map_component.state.height], 0,22,256,true);
            eventcode2viewport[eventcode].latitude = calc_viewport.center[1];
            eventcode2viewport[eventcode].longitude = calc_viewport.center[0];


            eventcode2viewport[eventcode].layers_on.forEach(function(layer_on){
                let already_added = false;
                map_instance.getStyle().layers.forEach(function(layer){
                    if (layer_on.id === layer.id){
                        already_added = true;
                    }
                })
                if (!already_added){
                    if (layer_on.id.slice(0,7) === "diagram"){
                        map_instance.addLayer({
                                "id": layer_on.id,
                                "type": "raster",
                                "source": {
                                      type: 'raster',
                                      tiles: ['https://api.mapbox.com/v4/'+ layer_on.source + '/{z}/{x}/{y}.png?access_token='+TOKEN],
                                      tileSize: 256,
                                  },
                                "layout": {
                                    "visibility": "visible"
                                },
                                "paint": {
                                    "raster-opacity": 0,
                                    "raster-opacity-transition":{
                                        "duration":1500
                                    }
                                }
                        })
                    }
                }
            })
        });
        // create list of layers that will be toggled on and off, vs permanent like geography, water etc.
        // push deaths outside because won't do all the other processing
        // layer_ids_to_toggle.push("deaths")
        map_instance.getStyle().layers.forEach(function(layer){
            if (layer.type === "raster" || layer.id === "deaths"){
                    layer_ids_to_toggle.push(layer.id);
                    if (layer.type !== "circle"){
                        map_instance.setLayoutProperty(layer.id, "visibility", "visible");
                        map_instance.setPaintProperty(layer.id, layer.type+'-opacity', 0);
                        map_instance.setPaintProperty(layer, layer.type+ "-opacity-transition", {"duration":1500});
                    }
                }
            }
        );

        layer_ids_to_toggle.forEach(function(layer_id) {
            let layer = map_instance.getLayer(layer_id);
            let layer_should_stay_on = false;
            eventcode2viewport[eventcode_currentpage].layers_on.forEach(function(layer_on_object) {
                if (layer.id === layer_on_object.id) {
                    layer_should_stay_on = true;
                }
            });
            if (!layer_should_stay_on) {
                if (layer.type === "circle") {
                    map_instance.setLayoutProperty(layer.id, "visibility", "none")
                } else {
                    map_instance.setPaintProperty(layer.id, layer.type + '-opacity', 0);
                    setTimeout(() => { map_instance.setLayoutProperty(layer.id, "visibility", "none") }, 1500);
                }
            }
        });

        // add layers for first page, since won't update at first
        // keep track of timeouts to cancel in case transition is interrupted
        eventcode2viewport[eventcode_currentpage].layers_on.forEach(function(layer_on_object){
            let timeout_id = setTimeout(function(){

                let layer = map_instance.getLayer(layer_on_object.id);
                if (layer.type === "circle" ){
                    map_instance.setLayoutProperty(layer.id, "visibility", "visible")
                }
                map_instance.setPaintProperty(layer.id, layer.type+'-opacity', 0.7);

            },  layer_on_object.time_on)
            timeouts.push(timeout_id);

            if (layer_on_object.time_off !== null){
                let timeout_id = setTimeout(function(){
                    let layer = map_instance.getLayer(layer_on_object.id);
                    if (layer.type === "circle"){
                        map_instance.setLayoutProperty(layer.id, "visibility", "none")
                    } else {
                        map_instance.setPaintProperty(layer.id, layer.type+'-opacity', 0);
                    }
                },  layer_on_object.time_off);
                timeouts.push(timeout_id);

            }
        });

        map_instance.addSource("markers_source", {
            type: "geojson",
            data: mediaData_geojson
        });
        map_instance.addLayer({
            "id": 'markers_layer',
            "type": "circle",
            'paint': {
                'circle-color': 'gray',
                'circle-radius': 5,
                'circle-opacity': .7,
                'circle-stroke-width': 1
            },
            "source": "markers_source"
        });
        pointer_to_map_component.filter_markers_chapter_narrative_platform(eventcode_currentpage);
        pointer_to_map_component.update_marker_highlighting();
        pointer_to_map_component.componentDidUpdate();

      });
      // this.setState({ firstLoadComplete: true })
  }

  filter_markers_chapter_narrative_platform(eventcode_in){
      //if archive, show all on_platform
      let filter_chapter_narrative;
      if (this.props.currentPage === 27){
          filter_chapter_narrative = [
              "all",
              ["==", 'on_platform', true],
              // ["!=", 's3', null]
          ];
      } else {
          filter_chapter_narrative = [
              "all",
              ["==", 'sequence_code', eventcode_in],
              ["==", 'in_narrative', true],
              // ["!=", 's3', null]
          ];
        }
        const map_instance = this.mapRef.getMap();
        map_instance.setFilter('markers_layer', filter_chapter_narrative);
  }

  componentWillUnmount() {
    // const map_instance = this.mapRef.getMap();
    window.removeEventListener('resize', this._resize);

  }

  componentDidUpdate(){
        const map_instance = this.mapRef.getMap();
        if (this.props.currentPage !== this.state.currentPage){

            this.setState({currentPage: this.props.currentPage});
            let eventcode = pagenum2eventcodes[this.props.currentPage];


            // transition duration
            let transition_distance = distance(this.state.viewport.latitude,
                this.state.viewport.longitude,
                eventcode2viewport[eventcode].latitude,
                eventcode2viewport[eventcode].longitude,
                'K');

            // let transition_zoom = Math.abs(this.state.viewport.zoom - eventcode2viewport[eventcode].zoom);
            var transition_duration;
            if (transition_distance === 0){
                transition_duration = 1;
            } else {
                transition_duration = 2000 + 10*transition_distance;
            }


            // viewport
            layer_ids_to_toggle.forEach(function(layer_id) {
                let layer = map_instance.getLayer(layer_id);
                let layer_should_stay_on = false;
                eventcode2viewport[eventcode].layers_on.forEach(function(layer_on_object) {
                    if (layer.id === layer_on_object.id) {
                        layer_should_stay_on = true;
                    }
                });
                if (!layer_should_stay_on) {
                    if (layer.type === "circle") {
                        map_instance.setLayoutProperty(layer.id, "visibility", "none")
                    } else {
                        map_instance.setPaintProperty(layer.id, layer.type + '-opacity', 0);
                        setTimeout(() => { map_instance.setLayoutProperty(layer.id, "visibility", "none") }, 1500);
                    }
                }
            });
            timeouts.forEach(function(timeout_id) {
                clearTimeout(timeout_id)
            })


            // keep track of timeouts to cancel in case transition is interrupted
            eventcode2viewport[eventcode].layers_on.forEach(function(layer_on_object) {
                let timeout_id = setTimeout(function() {
                    let layer = map_instance.getLayer(layer_on_object.id);
                    if(layer === undefined) { console.log('layer_on_object', layer_on_object) }
                    map_instance.setLayoutProperty(layer.id, "visibility", "visible")
                    if (layer.type !== "circle"){
                        map_instance.setPaintProperty(layer.id, layer.type + '-opacity', 0.7);
                    }

                }, transition_duration + layer_on_object.time_on)
                timeouts.push(timeout_id);

                if (layer_on_object.time_off !== null) {
                    let timeout_id = setTimeout(function() {
                        let layer = map_instance.getLayer(layer_on_object.id);
                        if (layer.type === "circle") {
                            map_instance.setLayoutProperty(layer.id, "visibility", "none")
                        } else {
                            map_instance.setPaintProperty(layer.id, layer.type + '-opacity', 0);
                            setTimeout(() => { map_instance.setLayoutProperty(layer.id, "visibility", "none") }, 1500);
                        }
                    }, transition_duration + layer_on_object.time_off);
                    timeouts.push(timeout_id);

                }
            });

            //filter out assets based on chapter and in_narrative
            this.filter_markers_chapter_narrative_platform(eventcode);

            let calc_viewport = geoViewport.viewport([
                eventcode2viewport[eventcode].west,
                eventcode2viewport[eventcode].south,
                eventcode2viewport[eventcode].east,
                eventcode2viewport[eventcode].north,
            ], [window.innerWidth, window.innerHeight], 0,22,256,true);

            const viewport = {
              ...this.state.viewport,
              latitude: calc_viewport.center[1],
              longitude: calc_viewport.center[0],
              zoom: calc_viewport.zoom*0.995,
              transitionDuration: transition_duration,
              transitionInterpolator: new FlyToInterpolator(),
              transitionEasing: d3.easeCubicInOut,
            };


            this.setState({viewport});


        }

        if (this.props.globalState.asset_in_view !== null && map_instance.getLayer('markers_layer') !== undefined){
            this.update_marker_highlighting();
        }

        if (this.props.globalState.timeline_request !== null){
            let bounds_requested;

            if (isMobile){
                switch (Number(this.props.globalState.timeline_request)){
                    case 1:
                        bounds_requested = {
                            west: -87.38843033598249,
                            south: 11.317010302668598,
                            east: -85.70364044394871,
                            north: 13.165519402650169,
                        };
                        break;
                    case 2:
                        bounds_requested = {
                            west: -86.37851459286291,
                            south: 12.000438742939489,
                            east: -86.16780653740629,
                            north: 12.231742155791464,
                        }
                        break;
                    case 3:

                        bounds_requested = {
                            west: -86.39559819410407,
                            south: 13.035270875800796,
                            east: -86.31196739030284,
                            north: 13.126731202539005,
                        }
                        break;
                    case 4:
                        bounds_requested = {
                            west: -86.37851459286291,
                            south: 12.000438742939489,
                            east: -86.16780653740629,
                            north: 12.231742155791464,
                        }
                        break;
                    default: bounds_requested = null;
                }
            } else {
                switch (Number(this.props.globalState.timeline_request)){
                    case 1:
                        bounds_requested = {
                            west: -88.00553327906125,
                            south: 10.895909479013573,
                            east: -84.12956035916083,
                            north: 13.646243714793503,
                        };
                        break;
                    case 2:
                        bounds_requested = {
                            west: -86.38374372340814,
                            south: 12.028516710352513,
                            east: -86.09281467637422,
                            north: 12.235085873895612,
                        }
                        break;
                    case 3:

                        bounds_requested = {
                            west: -86.39686447126246,
                            south: 13.042893673876657,
                            east: -86.28209714579147,
                            north: 13.124080094137815,
                        }
                        break;
                    case 4:
                        bounds_requested = {
                            west: -86.38374372340814,
                            south: 12.028516710352513,
                            east: -86.09281467637422,
                            north: 12.235085873895612,
                        }
                        break;
                    default: bounds_requested = null;
                }
            }

            let calc_viewport = geoViewport.viewport([
                bounds_requested.west,
                bounds_requested.south,
                bounds_requested.east,
                bounds_requested.north,
            ], [window.innerWidth, window.innerHeight], 0,22,256,true);

            let transition_distance = distance(this.state.viewport.latitude,
                this.state.viewport.longitude,
                calc_viewport.center[1],
                calc_viewport.center[0],
                'K');
            // var transition_duration;
            if (transition_distance === 0){
                transition_duration = 1;
            } else {
                transition_duration = 2000 + 10*transition_distance;
            }

            const viewport = {
              ...this.state.viewport,
              latitude: calc_viewport.center[1],
              longitude: calc_viewport.center[0],
              zoom: calc_viewport.zoom*0.995,
              transitionDuration: transition_duration,
              transitionInterpolator: new FlyToInterpolator(),
              transitionEasing: d3.easeCubicInOut,
            };

            this.props.setGlobalState({timeline_request: null});
            this.setState({viewport});
        }

        // if (this.props.globalState.landingVideo === true) {
        //   let mountNode = React.findDOMNode(this.mapRef);
        //   let unmount = React.unmountComponentAtNode(mountNode);
        //   console.log(unmount)
        // }
    }


    componentDidCatch(error, info) {
      console.log(error, info);
    }


    _resize = () => {
        this.setState({
            viewport: {
                ...this.state.viewport,
                width: this.props.width || window.innerWidth,
                height: this.props.height || window.innerHeight
            }
        });

    };

    _onViewportChange = viewport => {
        this.setState({viewport});
    }

    _onStyleChange = mapStyle => this.setState({mapStyle});

    _onClick = event => {
        const markers_feature = event.features.find(f => f.layer.id === 'markers_layer');

        if (markers_feature) {
            const property_interest = markers_feature.properties;
            if (property_interest !== undefined){
                this.props.setGlobalState({ asset_requested: property_interest["asset_code"]});
                this.props.setGlobalState({ asset_in_view: property_interest["asset_code"] });
                this.props.setGlobalState({ asset_in_view_full: property_interest });
                this.update_marker_highlighting();
            }
        }
    };

    geojsonify_mediaData(){
      let mediaData = this.props.globalState.mediaData;

      Object.keys(mediaData).forEach(function(event_key){
          Object.keys(mediaData[event_key]).forEach(function(subevent_key){

              Object.keys(mediaData[event_key][subevent_key]).forEach(function(chapter_key){
                  Object.keys(mediaData[event_key][subevent_key][chapter_key]).forEach(function(asset_key){

                      let asset = mediaData[event_key][subevent_key][chapter_key][asset_key];
                      let asset_geojson = {};
                      asset_geojson["geometry"] = {
                          coordinates:[
                              asset.lon,
                              asset.lat
                          ],
                          type: "Point",
                      }
                      asset_geojson["properties"] = asset;
                      mediaData_geojson.features.push(asset_geojson);

                  });
              });
          });

      });

    }

    update_marker_highlighting(){
        const map_instance = this.mapRef.getMap();
        let asset_in_view = this.props.globalState.asset_in_view;
        map_instance.setPaintProperty('markers_layer', 'circle-color',[
            'match',
            ['get', 'asset_code'],
            asset_in_view, 'white',
            'gray',
            ])
        map_instance.setPaintProperty('markers_layer', 'circle-radius',[
            'match',
            ['get', 'asset_code'],
            asset_in_view, 10,
            5,
            ])

        map_instance.setPaintProperty('markers_layer', 'circle-stroke-width',[
            'match',
            ['get', 'asset_code'],
            asset_in_view, 3,
            1,
        ]);
    }

render() {
    const {viewport} = this.state;
    const props = {};
    props.goToPage = this.goToPage;
    props.currentPage = this.state.currentPage;
    const mapClassName = `map ${this.props.globalState.is_archive === true ? '' : 'archiveMap'}`;
    // if (this.props.globalState.landingVideo !== true) {
    return (
      <div className={mapClassName}>
        <Legends currentPage = {this.props.globalState.currentPage}/>
        <ReactMapGL
          {...viewport}

          onViewportChange={(viewport) => {
              if (viewport.longitude < min_longitude){
                  viewport.longitude = min_longitude;
              }
              if (viewport.latitude < min_latitude){
                  viewport.latitude = min_latitude;
              }
              if (viewport.longitude > max_longitude){
                  viewport.longitude = max_longitude;
              }
              if (viewport.latitude > max_latitude){
                  viewport.latitude = max_latitude;
              }
              if (viewport.zoom < min_zoom){
                  viewport.zoom = min_zoom;
              }


            this.setState({ viewport });

        }}
          ref={ map => this.mapRef = map }
          mapStyle={"mapbox://styles/situmapping/cjnnccjqp07ih2sr4awjee3lz"}
          mapboxApiAccessToken={TOKEN}
          onClick={this._onClick}>
          <div className="data">{this.props.data}</div>
          <div className="nav" style={navStyle}>
          </div>
        </ReactMapGL>
      </div>
        );
      // } else return null;
  }

}


export default withGlobalState(Map);
