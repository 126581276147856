import React from 'react';
import { withGlobalState } from 'react-globally';

class Title extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: this.props.globalState.currentPage,
      currentLanguage: props.globalState.currentLanguage,
    };

  }

dataRender(titleData, language){
    // let regex = /Mother’s Day March/gi;
    // titleData[language].Landing_Subtext =titleData[language].Landing_Subtext.replace(regex,'March of the Mothers');
  return (<>
    {titleData[language].Landing_Title} | {titleData[language].Landing_Subtext}
  </>);
}


titleRender() {
   const globalData = this.props.globalState.data.data;
   const currentLanguage = this.props.globalState.currentLanguage;

   if (globalData !== undefined){
     const allChapterData = globalData[this.props.globalState.currentPage-1];
     const titleData = allChapterData[Object.keys(allChapterData)[0]];
       return this.dataRender(titleData, currentLanguage);
   }

 }


  render(){
    return(
    <>
      {this.titleRender()}
    </>)
  }

}

export default withGlobalState(Title);
