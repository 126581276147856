import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Main from './Main';
import * as serviceWorker from './serviceWorker';
import { Route, HashRouter } from 'react-router-dom';
import { Provider } from 'react-globally';
// import hacks from 'viewport-units-buggyfill/viewport-units-buggyfill.hacks';
// import ViewportUnitsBuggyfill from 'viewport-units-buggyfill';

// Add viewportunitbuggyfill for Safari portrait mode
// if(window.innerHeight > window.innerWidth){
//   ViewportUnitsBuggyfill.init({hacks: hacks})
// }

// let mql = window.matchMedia("(orientation: portrait)");
// if(mql.matches) {
// 	// Portrait orientation
//   ViewportUnitsBuggyfill.init({hacks: hacks})
// } else {
// 	// Landscape orientation
// }

const initialState = {
  active_index: 0,
  archiveCarousel: null,
  archiveData: [],
  asset_in_view: null,
  asset_in_view_full: null,
  asset_requested: null,
  ballistics_modal_open: false,
  cuidado_modal_open: true,
  currentImageIndex: 0,
  currentLanguage: 'english',
  currentPage: null,
  currentPDF: '',
  data: [],
  dataReceived: false,
  mediaData: [],
  hacky_scroll: true,
  inNarrativeArr: [],
  is_archive: false,
  landingVideo: false,
  mapLoaded: false,
  mediaDataReceived: false,
  mobile_carousel: true,
  reconVideo_open: false,
  orientation: '',
  pdf: '',
  timeline_request: null,
  videoSource: ''
}

const App = props => (
  <Provider globalState={initialState}>
      <Route path = {'/'} component ={Main} />
      <Route path = {'/es'} component = {Main} />
  </Provider>
);

ReactDOM.render(<HashRouter><App /></HashRouter>, document.getElementById('root'));

serviceWorker.unregister();
