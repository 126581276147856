import React from 'react';
import '../App.css';
import { withGlobalState } from 'react-globally';
import {Link} from 'react-router-dom';
import Home from '../icons/home.png';

class HomeButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      goToPage: this.props.goToPage,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(page) {this.props.goToPage(page)}

  renderHomeButton(){
    return <div className='homeButton'>
      <a href="http://www.marchadelasmadres.com">
        <img src={Home} alt="homeButton"></img>
      </a>
    </div>
  }

  render(){
    return <>
      {this.renderHomeButton()}
    </>
  }
}
export default withGlobalState(HomeButton);
