import React from 'react';
import '../App.css';

function ImageSlide(props) {
  const src = props.src;
  return (
    <div className="image-slide">
      <img src={src} key={src} alt={src} />
    </div>
  )
}

export default ImageSlide;
