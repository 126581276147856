import React from 'react';
import '../App.css';
import { withGlobalState } from 'react-globally';
import {Link} from 'react-router-dom';

class ArchiveButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentLanguage: this.props.globalState.currentLanguage,
      goToPage: this.props.goToPage,
    };
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick(page) {this.props.goToPage(page)}
  renderArchive(){
    return <div className='archiveButton'>
      <Link to= "/27" className={this.props.currentPage === 27 ? 'active' : ''}>
        <p>{this.props.globalState.currentLanguage === 'spanish' ? 'Archivo' : 'Archive'}</p>
      </Link>
    </div>
  }
  render(){
    if (this.props.globalState.currentPage < 27) {
    return <>
      {this.renderArchive()}
    </>
  } else return null;
}
}
export default withGlobalState(ArchiveButton);
