import React from 'react';
import { withGlobalState } from 'react-globally';
import {isMobile, isTablet} from 'react-device-detect';
import Title from '../partials/Title';
import Footer from '../partials/Footer';
import mobileDown from '../icons/mobileDown.png';
import mobileUp from '../icons/mobileUp.png';

class Event extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: this.props.currentPage,
      currentLanguage: this.props.globalState.currentLanguage,
      data: this.props.data,
      mediaData: this.props.globalState.mediaData,
      is_archive: false,
    };
    this.eventViewRef = React.createRef();
    this.eventRender = this.eventRender.bind(this);
    this.dataRender = this.dataRender.bind(this);
    this.mobileSwapCarouselForText = this.mobileSwapCarouselForText.bind(this);
  }

  componentDidUpdate(){
    if (typeof this.eventViewRef.current !== 'undefined' && this.eventViewRef.current !== null) {
      this.eventViewRef.current.scrollTop = 0;
    }
  }

  componentWillMount(){
    const globalData = this.props.globalState.data.data;
    this.setState({globalData: globalData})
    this.props.setGlobalState({ globalData: globalData });
    this.props.setGlobalState({ is_archive: false});
    console.log(isTablet)
  }

  replace_orgs_with_links(text){
      let regex_GIEI = /GIEI/g;
      let regex_SITU = /SITU/g;
      let regex_EAAF = /EAAF/g;
      let linked_text = text.replace(regex_GIEI, "<a href='http://www.gieinicaragua.org'>GIEI</a>");
      linked_text = linked_text.replace(regex_SITU, "<a href='https://situ.nyc/'>SITU</a>");
      linked_text = linked_text.replace(regex_EAAF, "<a href='https://www.eaaf.org/'>EAAF</a>");
      return linked_text
  }

  // Adding buttons and functionality for mobile event

  mobileSwapCarouselForText(){
    let mobileToggle;
    mobileToggle = this.props.globalState.mobile_carousel === true ? false : true;
    setTimeout(() => {
      this.props.setGlobalState({ mobile_carousel: mobileToggle })
  }, 1000);
  }

  areUDown(){
    let upOrDown;
    upOrDown = this.props.globalState.mobile_carousel === true ? mobileDown : mobileUp;
    return upOrDown;
  }

  eventClass() {
    let eventClass;
    eventClass = this.props.globalState.mobile_carousel === true ? "eventView closed" : "eventView opened";
    return eventClass;
  }

  dataRender(chapterData, language){
    const eventKey = chapterData.event_key;
    const subeventKey = eventKey.subevent;
    const chapter = eventKey.chapter;
    const chapterArr = this.state.mediaData[eventKey.event][subeventKey][chapter];
    const inNarrativeArr = [];
    for (let x = 0; x < chapterArr.length; x++) {
      if (chapterArr[x].in_narrative === 'TRUE') {
        const currentMedia = chapterArr[x];
        inNarrativeArr.push(currentMedia)
      }
    }
    if(isMobile && !isTablet){
      return (<div className="component">
          <div className="eventTextContainer">
            <div className="eventContainer">
              <div className="eventTitle"><Title /></div>
                <button className="mobileShowEventText" onClick={this.mobileSwapCarouselForText}><img src={this.areUDown()} alt="down"></img></button>
                <div className="container">
                  <p className={this.eventClass()} ref={this.eventViewRef} dangerouslySetInnerHTML={{__html:this.replace_orgs_with_links(chapterData[language].Landing_Info)}}></p>
                </div>
                  <div className="captionDivvy"><p className="chapterCaption">{chapterData[language].Chapter_Caption}</p></div>
          </div>
        </div>
      </div>)
    } else return(
      <div className="eventComponent">
          <div className="eventTextContainer">
            <div className="eventContainer">
              <div className="eventTitle"><Title /></div>
                <div className="textClass"><p className="eventText" dangerouslySetInnerHTML={{__html:this.replace_orgs_with_links(chapterData[language].Landing_Info)}}></p></div>
                  <div className="captionDivvy"><p className="chapterCaption">{chapterData[language].Chapter_Caption}</p></div>
                </div>
          <Footer />
        </div>
      </div>
    )
  }

  eventRender() {
     const globalData = this.props.globalState.data.data;
     const currentLanguage = this.props.globalState.currentLanguage;
     if (globalData !== undefined){
       const allChapterData = globalData[this.props.globalState.currentPage-1];
       const chapterData = allChapterData[Object.keys(allChapterData)[0]];
       if (chapterData.event_key !== undefined) {
         return this.dataRender(chapterData, currentLanguage);
       } else {
         return null
       }
     }
   }

  render() {
    return (
      <>
        {this.eventRender()}
      </>
    )
  }
}

export default withGlobalState(Event);
