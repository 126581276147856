import React, { Component } from 'react';
import { withGlobalState } from 'react-globally';
import Footer from '../partials/Footer';
import '../App.css';

const deathStats = '109';
const injuryStats = '1400+';
const detaineeStats = '690+';

class Landing extends Component{
  constructor(props){
    super(props);
    this.state = {
      currentLanguage: this.props.globalState.currentLanguage,
      data: [],
      dataReceived: false,
      goToPage: this.props.goToPage,
      is_archive: false,
        }
  }

  componentWillMount() {
}

  componentWillUnmount() {
  }
  replace_orgs_with_links(text){
      let regex_GIEI = /GIEI/g;
      let regex_SITU = /SITU/g;
      let regex_EAAF = /EAAF/g;
      let linked_text = text.replace(regex_GIEI, "<a href='http://www.gieinicaragua.org'>GIEI</a>");
      linked_text = linked_text.replace(regex_SITU, "<a href='https://situ.nyc/'>SITU</a>");
      linked_text = linked_text.replace(regex_EAAF, "<a href='https://www.eaaf.org/'>EAAF</a>");
      return linked_text
  }

  dataRender(landingData, language){
    if (this.props.globalState.data.data) {
      return( <div className="component">
              <div className="textWrapper">
                <h3 className="intro">{landingData[language].Intro}</h3>
                <hr className="introBreak"></hr>
                <h1 className="deathStats">{deathStats}</h1>
                <h2 className="deathTitle">{landingData[language].Landing_Death_Caption}</h2>
                <h1 className="detaineeStats">{detaineeStats}</h1>
                <h2 className="detaineeTitle">{landingData[language].Landing_Detainee_Caption}</h2>
                <h1 className="injuryStats">{injuryStats}</h1>
                <h2 className="injuryTitle">{landingData[language].Landing_Injury_Caption}</h2>
              </div>
                <p className="introText1" dangerouslySetInnerHTML={{__html:this.replace_orgs_with_links(landingData[language].Landing_Info)}}></p>
              <Footer />
          </div>)
  }
}

  landingRender() {
    if (this.props.globalState.data.data) {
      const globalData = this.props.globalState.data.data;
      const currentLanguage = this.props.globalState.currentLanguage;
      let landingData = '';
      switch (currentLanguage) {
        case 'english':
          landingData = globalData[0].page_1_landing;
          return this.dataRender(landingData, currentLanguage);
        case 'spanish':
          landingData = globalData[0].page_1_landing;
          return this.dataRender(landingData, currentLanguage);
        default: return null;
      }
    }
  }

  render() {
    if (this.props.currentPage !== 1){
      return null
    } else {
      return (
        <div className="landingScroll">
          <div className="landing">
          {this.landingRender()}
        </div>
      </div>
      )
    }
  }
}

export default withGlobalState(Landing);
