import React from 'react';
import { withGlobalState } from 'react-globally';
import Timeline from './Timeline';
import moment from 'moment';
require('moment/locale/es.js');

class Archive extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: this.props.currentPage,
      currentLanguage: this.props.globalState.currentLanguage,
      scrollPosition: '0',
      data: this.props.data,
      mediaData: this.props.globalState.mediaData,
      archiveData: [],
      is_archive: this.props.globalState.is_archive,
    };
  }

componentWillMount(){
    let flattenedArchiveData = [];
    let mediaData = this.state.mediaData;
    Object.keys(mediaData).forEach(function(event_key){
        Object.keys(mediaData[event_key]).forEach(function(subevent_key){
            Object.keys(mediaData[event_key][subevent_key]).forEach(function(chapter_key){
                Object.keys(mediaData[event_key][subevent_key][chapter_key]).forEach(function(asset_key){
                    let asset = mediaData[event_key][subevent_key][chapter_key][asset_key];
                    flattenedArchiveData.push(asset);
                });
            });
        });
    });
  // Okay, let's loop through archive data
  for (var x = 0; x < flattenedArchiveData.length; x++){
    const this_element = flattenedArchiveData[x];
    /* Okay, some dumb checking because month names aren't consistent */
    moment.locale('es');
    if (this_element.date !== null){
      const this_month = this_element.date.split('-')[1];
      if (this_month === 'Apr') {
        moment.locale('en');
      }
    }
    const start_time = moment(this_element.date + ' ' + this_element.start_time, 'DD-MMM-YYYY HH:mm:ss');
    const duration = moment.duration(this_element.duration);
    const end_time = start_time.clone().add(duration);
      this_element.start = start_time.toDate();
      this_element.end = end_time.toDate();
      this_element.label = '';
      this_element.steps = [];
  }
  this.setState({ archiveData: flattenedArchiveData })
  this.props.setGlobalState({ archiveData: flattenedArchiveData })

  if (this.props.globalState.currentPage === 27) {
    this.setState({ is_archive: true })
    this.props.setGlobalState({is_archive: true});
  }

}

componentWillUnmount(){
  
}


  render() {
    if (this.props.currentPage !== 27){
      return null
    } else {
      return (
        <div className="archiveComponent">
          <div className="archive">
            <Timeline archiveData={this.state.archiveData} />
          </div>
        </div>
      )
    }
  }
}
export default withGlobalState(Archive);
