import React from 'react';
import '../App.css';
import UpArrow from '../icons/up.png';
import leftArrow from '../icons/left.png';
import {Link} from 'react-router-dom';
import {isMobile} from 'react-device-detect';

function ArrowPrev(props) {
  const prevPage = Number(props.currentPage) - 1;
  if ((props.currentPage < 27 && props.currentPage > 1) || (isMobile && (props.currentPage < 28 && props.currentPage > 1))) {
      return (
        <div className="upArrow">
              <Link to={'/' + prevPage} onClick={props.handleClick()}> <img src={isMobile ? leftArrow : UpArrow} alt="upArrow"/> </Link>
        </div>
    )} else if (props.currentPage === 1) {
      return (
        <div className="upArrow">
              <Link to={'/'} onClick={props.handleClick()}> <img src={isMobile ? leftArrow : UpArrow} alt="upArrow"/> </Link>
        </div>
      )
    } else return null
}

export default ArrowPrev;
