import React from 'react';
import { withGlobalState } from 'react-globally';
import Arrow from '../partials/CarouselArrows';
import CarouselMobileBars from '../partials/CarouselMobileBars';
import ImageSlide from '../partials/ImageSlide';
import Video from '../partials/Video';
import '../App.css';

class Carousel extends React.Component {
  constructor (props) {
   super(props);
   this.state = {
     mediaData: this.props.mediaData,
     currentImageIndex: 0,
     currentLanguage: this.props.currentLanguage,
     currentPage: this.props.currentPage,
     error: ''
   };
   this.nextSlide = this.nextSlide.bind(this);
   this.previousSlide = this.previousSlide.bind(this);
   this.renderMobileAssetOnClick = this.renderMobileAssetOnClick.bind(this);
 }

 componentDidCatch(error, info) {
   this.setState({ error: error })
   console.log(error, info);
 }

 componentDidMount(){
   const mediaData = this.props.globalState.mediaData;
   this.setState({mediaData: mediaData})
   this.props.setGlobalState({ mediaData: mediaData });
   this.update_asset_in_view(0);
 }

 componentDidUpdate(prevProps) {
   if (this.props.currentPage !== prevProps.currentPage) {
     this.setState ({mediaData: this.props.globalState.mediaData});
     this.props.setGlobalState ({mediaData: this.props.globalState.mediaData});
     this.setState({currentImageIndex: 0});
     this.update_asset_in_view(0);
   }
   // Check if markerRequest has been made
    if (this.props.globalState.asset_requested !== null) {
        let asset_code = this.props.globalState.asset_requested;
        this.props.setGlobalState({ asset_requested: null });
        if (this.props.globalState.currentPage !== 27){
            this.setState({currentImageIndex: this.find_index_asset_code(asset_code)});
            this.props.setGlobalState({asset_in_view: asset_code});
            this.render();
        } else {

        }

    }
   if (this.props.globalState.archiveCarousel !== null){
     this.props.setGlobalState({ archiveCarousel: null });
     // this.setState({mediaData: [this.props.globalState.archiveCarousel]});
     // this.props.setGlobalState({ mediaData: [this.props.globalState.archiveCarousel] })
     this.render();
   }
 }

 renderMobileAssetOnClick(e, in_Narr_asset, x) {
       this.props.setGlobalState({ asset_requested: in_Narr_asset });
       this.props.setGlobalState({ active_index: x });
}

mobileClass(){
  let mobileCarouselClass;
  mobileCarouselClass = this.props.globalState.mobile_carousel === true ? "carousel opened" : "carousel closed";
  return mobileCarouselClass;
}

 previousSlide (inNarrativeArr) {
  const lastIndex = inNarrativeArr.length - 1;
  const { currentImageIndex } = this.state;
  const shouldResetIndex = currentImageIndex === 0;
  const index =  shouldResetIndex ? lastIndex : currentImageIndex - 1;
  this.update_asset_in_view(index);
      this.setState({
        currentImageIndex: index
      })
  }

nextSlide (inNarrativeArr) {
  const lastIndex = inNarrativeArr.length - 1;
  const { currentImageIndex } = this.state;
  const shouldResetIndex = currentImageIndex === lastIndex;
  const index =  shouldResetIndex ? 0 : currentImageIndex + 1;
  this.update_asset_in_view(index);
      this.setState({
        currentImageIndex: index
      })
  }

update_asset_in_view(index_in){
  const globalData = this.props.globalState.data.data;
  const allChapterData = globalData[this.props.currentPage - 1];
  const renderData = allChapterData[Object.keys(allChapterData)[0]];
  const eventKey = renderData.event_key;
  if (eventKey !== undefined){
    const subeventKey = eventKey.subevent;
    const chapter = eventKey.chapter;
    const chapterArr = this.state.mediaData[eventKey.event][subeventKey][chapter];

    let inNarrativeArr = [];
    for (let x = 0; x < chapterArr.length; x++) {
      if (chapterArr[x].in_narrative === true) {
        let currentMedia = chapterArr[x];
        inNarrativeArr.push(currentMedia)
      }
    }

    let media_in_view = inNarrativeArr[index_in];
    this.props.setGlobalState({asset_in_view: media_in_view.asset_code});
  } else if (this.props.currentPage === 27) {
    let asset = 'LEON001';
    this.props.setGlobalState({asset_in_view: asset});
    this.props.setGlobalState({asset_requested: asset});
    this.props.setGlobalState({asset_in_view_full: this.state.mediaData['18 de Abril'][1][1][0]});
  }
}

archiveRender(){
  if (this.props.globalState.asset_in_view_full !== null && this.props.globalState.asset_in_view_full.s3 !== null) {
      if (this.props.globalState.asset_in_view_full[this.props.globalState.currentLanguage] === "null"){
          return (
            <div className="archiveCarousel">
              {(this.props.globalState.asset_in_view_full.type === "Photo") ? (
                <ImageSlide src={this.s3_to_cloudfront(this.props.globalState.asset_in_view_full.s3)} />
              ) : (
                  <Video src={this.s3_to_cloudfront(this.props.globalState.asset_in_view_full.s3)} />
                )}
            </div>)
      } else {
          return (
            <div className="archiveCarousel">
              {(this.props.globalState.asset_in_view_full.type === "Photo") ? (
                <ImageSlide src={this.s3_to_cloudfront(this.props.globalState.asset_in_view_full.s3)} />
              ) : (
                  <Video src={this.s3_to_cloudfront(this.props.globalState.asset_in_view_full.s3)} />
                )}
              <div className="carouselCaption"><p className="carouselCaption">{this.props.globalState.asset_in_view_full[this.props.globalState.currentLanguage]}</p></div>
            </div>)
      }

  } else {
    return null
  }
}

carouselClass() {
  let carouselClass;
  carouselClass = this.props.globalState.mobile_carousel === true ? "mobileCarouselContainer" : "mobileCarouselContainerClosed";
  return carouselClass
}

mediaRender(data, language){
  const eventKey = data.event_key;
  const subeventKey = eventKey.subevent;
  const chapter = eventKey.chapter;
  const chapterArr = this.state.mediaData[eventKey.event][subeventKey][chapter];
  let inNarrativeArr = [];
  for (let x = 0; x < chapterArr.length; x++) {
    if (chapterArr[x].in_narrative === true) {
      const currentMedia = chapterArr[x];
      inNarrativeArr.push(currentMedia)
    }
  }
  const currentMedia = inNarrativeArr[this.state.currentImageIndex];

  if (currentMedia !== undefined && currentMedia.s3 !== null) {
      if (inNarrativeArr.length === 1){
          return (
            <div className={this.mobileClass()}>
              {(currentMedia.type === "Photo") ? (
                <ImageSlide src={this.s3_to_cloudfront(currentMedia.s3)} />
              ) : (
                  <Video src={this.s3_to_cloudfront(currentMedia.s3)} />
                )}
              <div className="carouselCaption"><p className="carouselCaption">{currentMedia[language]}</p></div>
              <CarouselMobileBars
                onClick={this.renderMobileAssetOnClick}/>
            </div>)
      } else {
          return (
            <div className={this.mobileClass()}>
              <Arrow
                direction="left"
                clickFunction={() => this.previousSlide(inNarrativeArr)}
                glyph="&#9664;" />
              {(currentMedia.type === "Photo") ? (
                <ImageSlide src={this.s3_to_cloudfront(currentMedia.s3)} />
              ) : (
                  <Video src={this.s3_to_cloudfront(currentMedia.s3)} />
                )}
              <Arrow
                direction="right"
                clickFunction={() => this.nextSlide(inNarrativeArr)}
                glyph="&#9654;" />
              <div className="carouselCaption"><p className="carouselCaption">{currentMedia[language]}</p></div>
                <CarouselMobileBars
                  onClick={this.renderMobileAssetOnClick}/>
            </div>)
      }
  } else {
    // console.log('FAILLL', currentMedia);
    // console.log('this.state', this.state);
    // console.log('inNarrativeArr', inNarrativeArr);
    // console.log('data', data);
    // console.trace();
    return null;
  }
}
s3_to_cloudfront(s3_url){
  let s3_prefix = 's3.amazonaws.com/eaaf.nicaragua/';
  let s3_index = s3_url.search(s3_prefix);
  return 'http://d3tib391ibn3y8.cloudfront.net/' + s3_url.slice(s3_index + s3_prefix.length);
}

carouselRender() {
  const globalData = this.props.globalState.data.data;
  const currentLanguage = this.props.globalState.currentLanguage;
  if (globalData !== undefined){
    const allChapterData = globalData[this.props.globalState.currentPage - 1];
    const renderData = allChapterData[Object.keys(allChapterData)[0]];
    if (renderData.event_key !== undefined) {
      return <div className={this.carouselClass()}>
        {this.mediaRender(renderData, currentLanguage)}
      </div>
    }
  }
}

find_index_asset_code(asset_code){
      let index = 0;
      let correct_index = 0;

      const globalData = this.props.globalState.data.data;
      const allChapterData = globalData[this.props.globalState.currentPage - 1];
      const renderData = allChapterData[Object.keys(allChapterData)[0]];
      const eventKey = renderData.event_key;
      const subeventKey = eventKey.subevent;
      const chapter = eventKey.chapter;
      const chapterArr = this.state.mediaData[eventKey.event][subeventKey][chapter];

      let inNarrativeArr = [];
      for (let x = 0; x < chapterArr.length; x++) {
        if (chapterArr[x].in_narrative === true) {
          const currentMedia = chapterArr[x];
          inNarrativeArr.push(currentMedia)
        }
      }

      inNarrativeArr.forEach(function(asset){
          if (asset.asset_code === asset_code){
              correct_index = index;
          }
          index += 1;
      })
      return correct_index;
  }

  render () {
    if (this.state.mediaData !== undefined) {
      if (this.props.currentPage === 27){
        return (
          <>
            { this.archiveRender() }
          </>
        )
      } else {
        return (
          <>
            {this.carouselRender()}
          </>)
      }
    } else
    console.log(this.state.error);
    return null;
  }
}

export default withGlobalState(Carousel);
