import React from 'react';

function Video(props) {
  const src = props.src;
  const addPoster = "#t=1";
   return (
      <div className="videoDiv">
        <video className="lightboxVideo" id="lightbox-video" key={src} controls>
          <source src={src + addPoster} />
          Your browser does not support the video tag.
        </video>
      </div>
       )
 }

 export default Video;
