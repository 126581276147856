import React from 'react';
import '../App.css';
import { withGlobalState } from 'react-globally';
import legendEA from '../images/eng_A.png';
import legendEAB from '../images/eng_AB.png';
import legendEABC from '../images/eng_ABC.png';
import legendEABCD from '../images/eng_ABCD.png';
import legendEABD from '../images/eng_ABD.png';
import legendEAC from '../images/eng_AC.png';
import legendEACD from '../images/eng_ACD.png';
import legendEBC from '../images/eng_BC.png';
import legendEC from '../images/eng_C.png';
import legendSA from '../images/esp_A.png';
import legendSAB from '../images/esp_AB.png';
import legendSABC from '../images/esp_ABC.png';
import legendSABCD from '../images/esp_ABCD.png';
import legendSABD from '../images/esp_ABD.png';
import legendSAC from '../images/esp_AC.png';
import legendSACD from '../images/esp_ACD.png';
import legendSBC from '../images/esp_BC.png';
import legendSC from '../images/esp_C.png';

class Legends extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: props.globalState.currentPage,
      currentLanguage: props.globalState.currentLanguage,
    };
  }

weAreLegends(){
  if (this.props.globalState.currentLanguage === 'english') {
      switch(this.props.globalState.currentPage){
        case 3:
          return <img src={legendEACD} alt="legend"/>;
        case 4:
          return <img src={legendEA} alt="legend"/>;
        case 5:
          return <img src={legendEACD} alt="legend"/>;
        case 6:
          return <img src={legendEAC} alt="legend"/>;
        case 7:
          return <img src={legendEABD} alt="legend"/>;
        case 8:
          return <img src={legendEACD} alt="legend"/>;
        case 9:
          return <img src={legendEC} alt="legend"/>;
        case 11:
          return <img src={legendEAB} alt="legend"/>;
        case 12:
          return <img src={legendEAB} alt="legend"/>;
        case 13:
          return <img src={legendEABD} alt="legend"/>;
        case 14:
          return <img src={legendEABC} alt="legend"/>;
        case 16:
          return <img src={legendEABCD} alt="legend"/>;
        case 17:
          return <img src={legendEAC} alt="legend"/>;
        case 18:
          return <img src={legendEACD} alt="legend"/>;
        case 19:
          return <img src={legendEAC} alt="legend"/>;
        case 20:
          return <img src={legendEBC} alt="legend"/>;
        case 22:
          return <img src={legendEA} alt="legend"/>;
        case 23:
          return <img src={legendEACD} alt="legend"/>;
        case 24:
          return <img src={legendEACD} alt="legend"/>;
        case 25:
          return <img src={legendEACD} alt="legend"/>;
        case 26:
          return <img src={legendEA} alt="legend"/>;
          default: return null;
      }
    } else {
      switch (this.props.globalState.currentPage){
        case 3:
          return <img src={legendSACD} alt="legend"/>;
        case 4:
          return <img src={legendSA} alt="legend"/>;
        case 5:
          return <img src={legendSACD} alt="legend"/>;
        case 6:
          return <img src={legendSAC} alt="legend"/>;
        case 7:
          return <img src={legendSABD} alt="legend"/>;
        case 8:
          return <img src={legendSACD} alt="legend"/>;
        case 9:
          return <img src={legendSC} alt="legend"/>;
        case 11:
          return <img src={legendSAB} alt="legend"/>;
        case 12:
          return <img src={legendSAB} alt="legend"/>;
        case 13:
          return <img src={legendSABD} alt="legend"/>;
        case 14:
          return <img src={legendSABC} alt="legend"/>;
        case 16:
          return <img src={legendSABCD} alt="legend"/>;
        case 17:
          return <img src={legendSAC} alt="legend"/>;
        case 18:
          return <img src={legendSACD} alt="legend"/>;
        case 19:
          return <img src={legendSAC} alt="legend"/>;
        case 20:
          return <img src={legendSBC} alt="legend"/>;
        case 22:
          return <img src={legendSA} alt="legend"/>;
        case 23:
          return <img src={legendSACD} alt="legend"/>;
        case 24:
          return <img src={legendSACD} alt="legend"/>;
        case 25:
          return <img src={legendSACD} alt="legend"/>;
        case 26:
          return <img src={legendSA} alt="legend"/>;
        default: return null;
    }
  }
}

  render(){
    return(
      <div className="legends">
        {this.weAreLegends()}
      </div>
    )
  }
}

export default withGlobalState(Legends);
