import React from 'react';
import { withGlobalState } from 'react-globally';
import Close from '../icons/close1.png';

class CuidadoModal extends React.Component {

  onCloseModal = () => {
    this.props.setGlobalState({ cuidado_modal_open: false });
  };

  render() {
    if (!this.props.isOpen) {
      return null;
    }

    return (
      <>
      <div className="modalBackdrop"></div>
        <div className="cuidadoModal">
          <img src={Close} onClick={this.onCloseModal} alt="close"/>
          <h3 className="cuidado">La plataforma ha sido optimizada para visualizarla  en computadoras de escritorio.</h3>
          <h3 className="cuidado">This platform has been optimized for desktop viewing.</h3>
      </div>
      </>
    );
  }
}


export default withGlobalState(CuidadoModal);
